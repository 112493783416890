<div class="relative box-border">
  <mat-icon
    class="absolute right-0 -top-1 cursor-pointer"
    svgIcon="heroicons_solid:x"
    (click)="onClose()"
  ></mat-icon>

  <p class="max-w-3/4 mx-auto mb-6 sm:mb-4 text-center font-bold text-xl leading-6" mat-dialog-title>
    {{ data ? 'Редактировать' : 'Добавить' }} роль
  </p>

  <form
    class="flex flex-col gap-4 overflow-y-scroll max-h-[calc(70vh-68px)] mb-4 com-perfect-scrollbar pr-2"
    [formGroup]="formGroup"
  >
    <div>
      <div>
        <com-form-label text="Название" type="block" [required]="true"></com-form-label>
        <com-form-input [control]="formGroup.controls.name"></com-form-input>
      </div>
      <div>
        <com-form-label text="Тип" type="block" [required]="true"></com-form-label>
        <mat-form-field class="w-full">
          <mat-select formControlName="type">
            <mat-option *ngFor="let type of ROLE_TYPES" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <com-form-label text="Уровень роли участника" type="block" [required]="true"></com-form-label>
        <com-form-input [control]="formGroup.controls.order" type="number"></com-form-input>
      </div>
      <div
        *ngIf="formGroup.controls.type.value === RoleTypesEnum.MANDATORY && formGroup.controls.required.value"
      >
        <com-form-label text="Минимальное кол-во участников" type="block" [required]="true"></com-form-label>
        <com-form-input
          showHint="true"
          hint="от 1 до 100 участников в компромиссной модели"
          [control]="formGroup.controls.minimumMembers"
          type="number"
        ></com-form-input>
      </div>
      <div class="flex flex-col gap-2">
        <div>
          <com-form-label text="Параметры" class="text-xl" type="block"></com-form-label>
          <div>
            <com-form-checkbox
              [control]="formGroup.controls.hasDeputies"
              [label]="RoleParamsEnum.HAS_DEPUTIES"
            ></com-form-checkbox>
            <com-form-checkbox
              [control]="formGroup.controls.hasDublicate"
              [label]="RoleParamsEnum.HAS_DUBLICATE"
            ></com-form-checkbox>
            <com-form-checkbox
              [control]="formGroup.controls.requiredToIdealModel"
              [label]="RoleParamsEnum.REQUIRED_TO_IDEAL_MODEL"
            ></com-form-checkbox>
            <com-form-checkbox
              [control]="formGroup.controls.required"
              [label]="RoleParamsEnum.REQUIRED"
            ></com-form-checkbox>
          </div>
        </div>
        <div>
          <com-form-label text="Права доступа" class="text-xl" type="block"></com-form-label>
          <div>
            <div *ngFor="let group of roleAccesses; index as index">
              <com-form-label [text]="group[0].groupName" type="group" class="font-bold"></com-form-label>
              <div class="mt-1">
                <com-form-checkbox
                  *ngFor="let roleAccess of group; index as index"
                  [control]="formGroup.controls.accesses.controls[roleAccess.id]"
                  [label]="roleAccess.value"
                  [disabled]="roleAccess.disabled"
                  [matTooltip]="roleAccess.disabled ? 'Функциональный блок находится в работе' : null"
                ></com-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>

  <div class="h-12 w-full"></div>
  <footer class="absolute flex justify-end w-full bottom-0 pt-1">
    <button class="mx-1.5" mat-stroked-button mat-dialog-close="">Отмена</button>
    <button class="mx-1.5 min-w-24" (click)="confirm()" color="primary" mat-flat-button>
      <span>Cохранить</span>
    </button>
  </footer>
</div>
