import { Pipe, PipeTransform } from '@angular/core';
import { IMember } from '@common/types';

@Pipe({
  name: 'disableVote'
})
export class DisableVotePipe implements PipeTransform {
  transform(members: IMember[], userId: string): boolean {
    return members.some((m) => m.delegateTo && m.committeeMember.employee.id === userId);
  }
}
