import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  asyncScheduler,
  BehaviorSubject,
  debounceTime,
  filter,
  Subject,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import { EmployeeService, UnsubscribeService } from '@common/services';
import {
  CommitteeFormatId,
  GeneralFormValue,
  ICompromiseFormGroup,
  ICompromiseModel,
  IEmployee,
  IEmployeeOption,
  IMemberDto,
  IOption,
  IRole,
  IRrule,
  ProtocolFormValue,
  RRuleModel
} from '@common/types';
import { ModelsEnum, RoleAccessesEnum } from '@common/enums';
import moment from 'moment';
import { DATE_FORMAT, QUORUM_BOUNDARY_COUNT, UUID_EMPTY } from '@common/constants';
import { dateValidator, minDateTimeValidator } from '@common/utils/validators';
import { compareByField } from '@common/utils/util';
import { IMemberFormValue } from '@common/shared/components/form-groups/members/members.types';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';
import { IdealFormValue } from '@common/shared/components/form-groups/ideal/ideal.component';
import {
  employeeOptionMapper,
  memberModelToMemberDtoMapper
} from '@common/modules/committees/committees-form/utils/adaptors';
import { CommitteeMembersRoleService } from '@common/modules/committees/committees-form/services/committee-members-role.service';
import { uniqBy } from 'lodash';
import {
  ShortCut,
  ShortCutValue
} from '@common/shared/components/form-groups/no-system-members/no-system-members.types';
import { checkMinMembersInRole } from '@common/modules/committees/committees-form/utils';

export interface ICompromiseFormValue {
  id?: string;
  committeeGoals: string[];
  committeeTasks: string[];
  committeeParamAndTerms: string[];
  committeeParamAndTermMembers: string[];
  committeeFormatId: string;
  isDecisionModel: boolean;
  isCoordination: boolean;
  meetingRoomIds: string[];
  quorumBoundary: number;
  permissibleTransferCommittee: number;
  plannedPeriodOfTestDevelopment: string;
  responsibleOfTestDevelopmentId: string;
  rrule: RRuleModel;
  members: IMemberFormValue[];
  general: GeneralFormValue;
  protocol: ProtocolFormValue;
  accessTransferWithoutAccrualSanctions: string[];
  noSystemOptions: ShortCutValue[];
}

@Component({
  selector: 'com-compromise',
  templateUrl: './compromise.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class CompromiseComponent
  extends FormAbstractionComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Output() getFormValue = new EventEmitter<() => ICompromiseFormValue>();
  @Output() addShownRole = new EventEmitter<IRole>();
  @Output() removeShownRole = new EventEmitter<IRole>();

  @Input() committeeTypes: IOption[] = [];
  @Input() committeeSubTypes: IOption[] = [];
  @Input() committeeKinds: IOption[] = [];
  @Input() members: IMemberDto[] = [];
  @Input() divisionMembers: IMemberDto[] = [];
  @Input() model: ICompromiseModel;
  @Input() protocolOptions: IOption[] = [];
  @Input() isEdit = false;
  @Input() meetingRooms: IOption[];
  @Input() shownRoles: IRole[];
  @Input() hiddenRoles: IRole[];
  @Input() updateFormByIdeal: IdealFormValue;

  public formGroup: FormGroup<ICompromiseFormGroup>;
  public isNoRemoteFormat = new FormControl(false);
  public accessControl = new FormControl(false);
  public currentDate: string = moment().format(DATE_FORMAT);
  public ModelsEnum = ModelsEnum;
  public noSystemMembers: ShortCutValue[] = [];

  public isGeneralFormValid: () => boolean;
  public isMembersFormValid: () => boolean;
  public isProtocolFormValid: () => boolean;
  public isRruleFormValid: () => boolean;

  public membersForm: IMemberFormValue[] = [];
  public generalForm: GeneralFormValue;
  public getRruleFormValue: () => IRrule;
  public protocolForm: ProtocolFormValue;

  public selectorSearch$ = new Subject<string>();
  public employeeOptions$ = new BehaviorSubject<IEmployeeOption[]>([]);
  public updateGeneralForm$: Subject<GeneralFormValue> = new Subject<GeneralFormValue>();
  public updateRruleForm$: Subject<IRrule> = new Subject<IRrule>();
  public updateProtocolForm$: Subject<ProtocolFormValue> = new Subject<ProtocolFormValue>();
  public canTransferMembers$ = new BehaviorSubject<IOption[]>([]);

  @Output() checkTimeValidation = new EventEmitter<() => void>();

  constructor(
    public formBuilder: FormBuilder,
    private _employeeService: EmployeeService,
    private _cdr: ChangeDetectorRef,
    public membersRoleService: CommitteeMembersRoleService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
    this._createForm();
    this._searchSelectorChangeSub();
    this.initAccessControlSub();
    this.initMemberRoleSub();
  }

  ngOnInit(): void {
    this.emitFormMethods();
    this.getFormValue.emit(this._compromiseFormMapper.bind(this));
    this._updateForm();
  }

  ngAfterViewInit(): void {
    this.setValidMethodCheck(
      this.isGeneralFormValid,
      this.isMembersFormValid,
      this.isProtocolFormValid,
      this.isRruleFormValid
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('updateFormByIdeal' in changes && this.updateFormByIdeal) {
      this._updateFormByIdealMode(this.updateFormByIdeal);
    }
  }

  public onNoSystemSelected(options: ShortCutValue[]): void {
    this.noSystemMembers = options;
  }

  public membersChange(members: IMemberFormValue[]): void {
    this.membersForm = members;
    const userIds = members.map((m) => m?.employeeOption?.id);
    this.noSystemMembers = this.noSystemMembers.filter((m) => !userIds.includes(m.value));
  }

  private _updateForm(): void {
    if (this.model) {
      this._setForm(this.model);
      const accessTransferWithoutAccrualSanctions = uniqBy(
        [...this.members, ...this.divisionMembers]
          .filter((data) =>
            data.role.accesses.includes(
              RoleAccessesEnum.CAN_TRANSFER_COMMITTEE_REPORTING_PERIOD_WITHOUT_ACCRUAL_SANCTIONS
            )
          )
          .map((data) => ({ id: data.employee?.id, name: data.employee?.fullName })),
        (x) => x.id
      );
      this.canTransferMembers$.next(accessTransferWithoutAccrualSanctions);

      this._initFormSub();
      asyncScheduler.schedule(() => {
        const { protocolAccess, formationPeriod, rrule } = this.model;
        this._updateGeneralForm(this.model);
        this._updateProtocolForm(protocolAccess, formationPeriod);
        this.updateRruleForm$.next(rrule);
      });
      this.initNoSystemMember();
    }
  }

  private initNoSystemMember(): void {
    const { positions, employees } = this.model.nonSystemMembersModel;

    this.noSystemMembers = [
      ...positions.map((position) => ({
        shortcut: ShortCut.Positions,
        value: position,
        name: position
      })),
      ...employees.map((employee) => ({
        shortcut: ShortCut.Users,
        value: employee.id,
        name: employee.fullName
      }))
    ];
  }

  private initMemberRoleSub(): void {
    this.membersRoleService
      .getMemberRoleChanged()
      .pipe(
        tap(([, compromise, division]) => {
          const allMembers = this.membersRoleService.getAllSelectedMemberOption();
          const accessTransferWithoutAccrualSanctions = uniqBy(
            [...compromise, ...division]
              .filter(([, roles]) =>
                roles
                  .map((x) => x.accesses)
                  .flat()
                  .includes(
                    RoleAccessesEnum.CAN_TRANSFER_COMMITTEE_REPORTING_PERIOD_WITHOUT_ACCRUAL_SANCTIONS
                  )
              )
              .map(([userId]) => {
                return allMembers.find((x) => x.id === userId);
              }),
            (x) => x.id
          );

          this.canTransferMembers$.next(accessTransferWithoutAccrualSanctions);
        })
      )
      .subscribe();
  }

  private _updateGeneralForm({ name, committeeKind, committeeSubType }: ICompromiseModel): void {
    this.updateGeneralForm$.next({
      name,
      committeeKindId: committeeKind?.id,
      committeeSubTypeId: committeeSubType?.id
    });
  }

  private _updateProtocolForm(protocolAccess: IEmployee, formationPeriod: number): void {
    this.updateProtocolForm$.next({
      formationPeriod,
      protocolAccessEmployee: {
        id: protocolAccess.id,
        name: protocolAccess.fullName
      }
    });
  }

  private _updateFormByIdealMode({ members, rrule, general, protocol }: IdealFormValue): void {
    const { name, committeeKindId, committeeSubTypeId } = general;

    const compromiseMembers = members.map(memberModelToMemberDtoMapper);
    this.members = checkMinMembersInRole(compromiseMembers, this.shownRoles);

    asyncScheduler.schedule(() => {
      this.updateRruleForm$.next(rrule);
      this.updateGeneralForm$.next({ name, committeeKindId, committeeSubTypeId });
      this.updateProtocolForm$.next(protocol);
    });
  }

  private _setForm(model: ICompromiseModel): void {
    const {
      id,
      committeeFormat,
      isCoordination,
      isDecisionModel,
      committeeMeetingRooms,
      committeeGoals,
      committeeTasks,
      hasTest,
      plannedPeriodOfTestDevelopment,
      responsibleOfTestDevelopment,
      committeeParamAndTerms,
      committeeParamAndTermMembers,
      quorumBoundary,
      permissibleTransferCommittee,
      deadlineForPassingTest,
      accessTransferWithoutAccrualSanctions
    } = model;
    const putCommittee = {
      id,
      isCoordination,
      isDecisionModel,
      hasTest,
      quorumBoundary,
      meetingRoomIds: committeeMeetingRooms
        .map((roomId) => this.meetingRooms.find((room) => room.id === roomId))
        .filter((room) => room)
        .sort(compareByField('name'))
        .map((room) => room.id) as string[],
      committeeGoals: committeeGoals.map((x) => x.name) || [],
      committeeTasks: committeeTasks.map((x) => x.name) || [],
      plannedPeriodOfTestDevelopment: plannedPeriodOfTestDevelopment
        ? moment(plannedPeriodOfTestDevelopment).format(DATE_FORMAT)
        : null,
      responsibleOfTestDevelopment: {
        id: responsibleOfTestDevelopment?.id,
        name: responsibleOfTestDevelopment?.fullName
      },
      committeeParamAndTerms: committeeParamAndTerms.map((x) => x.name) || [],
      committeeParamAndTermMembers: committeeParamAndTermMembers.map((x) => x.name) || [],
      permissibleTransferCommittee: permissibleTransferCommittee,
      deadlineForPassingTest: deadlineForPassingTest
        ? moment(deadlineForPassingTest).format(DATE_FORMAT)
        : null,
      accessTransferWithoutAccrualSanctions: accessTransferWithoutAccrualSanctions
    };

    if (responsibleOfTestDevelopment || plannedPeriodOfTestDevelopment) {
      this.accessControl.setValue(true);
      this.formGroup.controls.responsibleOfTestDevelopment.setValidators([Validators.required]);
      this.formGroup.controls.plannedPeriodOfTestDevelopment.setValidators([Validators.required]);

      if (plannedPeriodOfTestDevelopment) {
        this.currentDate = putCommittee.plannedPeriodOfTestDevelopment;
        this.formGroup.controls.plannedPeriodOfTestDevelopment.setValidators([
          Validators.required,
          dateValidator({ min: this.currentDate })
        ]);
      }
    }

    this.isNoRemoteFormat.setValue(committeeFormat?.id === CommitteeFormatId.noRemote);

    this.formGroup.patchValue(putCommittee, {
      emitEvent: false,
      onlySelf: true
    });

    this.formGroup.controls.meetingRoomIds.updateValueAndValidity();
    this.formGroup.controls.accessTransferWithoutAccrualSanctions.updateValueAndValidity();
    this._populateFormArray(this.formGroup.controls.committeeGoals, putCommittee.committeeGoals);
    this._populateFormArray(this.formGroup.controls.committeeTasks, putCommittee.committeeTasks);
    this._populateFormArray(
      this.formGroup.controls.committeeParamAndTerms,
      putCommittee.committeeParamAndTerms
    );
    this._populateFormArray(
      this.formGroup.controls.committeeParamAndTermMembers,
      putCommittee.committeeParamAndTermMembers
    );
  }

  private _createForm(): void {
    this.formGroup = this.formBuilder.group({
      id: [],
      committeeGoals: new FormArray([new FormControl()]),
      committeeTasks: new FormArray([new FormControl()]),
      committeeParamAndTerms: new FormArray([new FormControl()]),
      isCoordination: [false],
      isDecisionModel: [false],
      meetingRoomIds: [[UUID_EMPTY], [Validators.required]] as unknown as FormControl<string[]>,
      quorumBoundary: [QUORUM_BOUNDARY_COUNT, [Validators.required, Validators.min(0), Validators.max(100)]],
      permissibleTransferCommittee: [0, [Validators.min(0)]],
      committeeParamAndTermMembers: new FormArray([new FormControl()]),
      // TODO need to fix ts conflict
      plannedPeriodOfTestDevelopment: [null as string, [minDateTimeValidator(moment().format(DATE_FORMAT))]],
      responsibleOfTestDevelopment: [],
      accessTransferWithoutAccrualSanctions: [[]]
    });
  }

  private _compromiseFormMapper(): ICompromiseFormValue {
    const {
      committeeGoals,
      committeeTasks,
      committeeParamAndTerms,
      committeeParamAndTermMembers,
      isDecisionModel,
      isCoordination,
      meetingRoomIds,
      quorumBoundary,
      permissibleTransferCommittee,
      plannedPeriodOfTestDevelopment,
      responsibleOfTestDevelopment
    } = this.formGroup.getRawValue();
    let { accessTransferWithoutAccrualSanctions } = this.formGroup.getRawValue();
    const rrule = this.getRruleFormValue();
    accessTransferWithoutAccrualSanctions = accessTransferWithoutAccrualSanctions.filter((empId) =>
      this.canTransferMembers$.value.map((x) => x.id).includes(empId)
    );
    return {
      meetingRoomIds,
      quorumBoundary,
      committeeFormatId: this.isNoRemoteFormat.value ? CommitteeFormatId.noRemote : CommitteeFormatId.remote,
      isDecisionModel,
      isCoordination,
      permissibleTransferCommittee,
      committeeGoals: committeeGoals.filter(Boolean) || [],
      committeeTasks: committeeTasks.filter(Boolean) || [],
      committeeParamAndTerms: committeeParamAndTerms.filter(Boolean) || [],
      committeeParamAndTermMembers: committeeParamAndTermMembers.filter(Boolean) || [],
      plannedPeriodOfTestDevelopment,
      responsibleOfTestDevelopmentId: (responsibleOfTestDevelopment?.id as string) || null,
      rrule: rrule.dtstarttime ? RRuleModel.omit(rrule) : rrule,
      members: this.membersForm,
      general: this.generalForm,
      protocol: this.protocolForm,
      accessTransferWithoutAccrualSanctions: accessTransferWithoutAccrualSanctions,
      noSystemOptions: this.noSystemMembers
    };
  }

  private _initFormSub(): void {
    this.formGroup.controls.plannedPeriodOfTestDevelopment.valueChanges
      .pipe(
        filter((value) => value === ''),
        tap(() => this.formGroup.controls.plannedPeriodOfTestDevelopment.setValue(null)),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();

    this.isNoRemoteFormat.valueChanges
      .pipe(
        tap((value) => {
          this.formGroup.controls.meetingRoomIds.setValue(value ? [] : [UUID_EMPTY]);
          this.formGroup.controls.meetingRoomIds.markAsTouched({
            onlySelf: true
          });
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _searchSelectorChangeSub(): void {
    this.selectorSearch$
      .pipe(
        debounceTime(50),
        filter((query) => typeof query === 'string'),
        switchMap((query) =>
          this._employeeService
            .retrieveEmployeeSearchForSelect(query || '')
            .pipe(tap((employees) => this.employeeOptions$.next(employees.map(employeeOptionMapper))))
        ),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _populateFormArray(formArray: FormArray, values: string[]): void {
    values.forEach((committeeGoal, index) => {
      if (index) {
        formArray.push(new FormControl(committeeGoal), {
          emitEvent: false
        });
      }
    });
    this._cdr.detectChanges();
  }

  private initAccessControlSub(): void {
    this.accessControl.valueChanges.pipe(takeUntil(this._unsubscribeService)).subscribe(() => {
      const { responsibleOfTestDevelopment, plannedPeriodOfTestDevelopment } = this.formGroup.controls;
      if (this.accessControl.value) {
        responsibleOfTestDevelopment.setValidators([Validators.required]);
        plannedPeriodOfTestDevelopment.setValidators([Validators.required]);
      } else {
        responsibleOfTestDevelopment.setValidators([]);
        plannedPeriodOfTestDevelopment.setValidators([]);
        responsibleOfTestDevelopment.reset();
        plannedPeriodOfTestDevelopment.reset();
      }
    });
  }
}
