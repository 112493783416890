import { Pipe, PipeTransform } from '@angular/core';
import { DAYS_IN_WEEK } from '@common/constants/count.const';
import { RruleRepeatEnum } from '@common/enums/rrule.enum';
import { DaysOffFormValue, IOption } from '@common/types';
import moment from 'moment';

@Pipe({
  name: 'repeatOptions'
})
export class RepeatOptionsPipe implements PipeTransform {
  transform(date: string, daysOff: DaysOffFormValue): IOption[] {
    const dtStart = moment(date);
    const monthlyWeekday = Math.floor((dtStart.date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);
    const isDayOff =
      daysOff &&
      ((daysOff.excludeSaturdays && dtStart.weekday() === 5) ||
        (daysOff.excludeSundays && dtStart.weekday() === 6));

    return [
      { id: RruleRepeatEnum.DAILY, name: 'Ежедневно' },
      { id: RruleRepeatEnum.DAILY_WEEKDAY, name: 'Ежедневно по будням' },
      ...(isDayOff
        ? []
        : [
            {
              id: RruleRepeatEnum.WEEKLY,
              name: `Еженедельно, ${dtStart.format('dddd')}`
            }
          ]),
      {
        id: RruleRepeatEnum.MONTHLY,
        name: `Ежемесячно, ${dtStart.format('D')} числа`
      },
      ...(isDayOff
        ? []
        : [
            {
              id: RruleRepeatEnum.MONTHLY_WEEKDAY,
              name: `Ежемесячно, ${monthlyWeekday}-й ${dtStart.format('dddd')}`
            }
          ]),
      {
        id: RruleRepeatEnum.MONTHLY_LAST_FRIDAY,
        name: 'Ежемесячно, последнюю пятницу'
      },
      {
        id: RruleRepeatEnum.YEARLY,
        name: `Ежегодно, ${dtStart.format('D MMMM')}`
      },
      ...(isDayOff
        ? []
        : [
            {
              id: RruleRepeatEnum.YEARLY_WEEKDAY,
              name: `Ежегодно, ${dtStart.format('MMMM')}, ${monthlyWeekday}-й ${dtStart.format('dddd')}`
            }
          ]),
      { id: RruleRepeatEnum.WORKDAY_EACH_MONTH, name: 'На N рабочий день каждого месяца' },
      { id: RruleRepeatEnum.WORKDAY_MONTHS, name: 'N рабочий день одного или нескольких месяцев' },
      { id: RruleRepeatEnum.WORKDAY_HALF_YEAR, name: 'Последний рабочий день полугодия' },
      { id: RruleRepeatEnum.CUSTOM, name: 'Свой вариант' }
    ];
  }
}
