import { IMemberDto, IRole } from '@common/types';
import { RoleTypesEnum } from '@common/enums';

export const checkMinMembersInRole = (members: IMemberDto[], roles: IRole[]): IMemberDto[] => {
  const result: IMemberDto[] = [...members];

  roles.map((role) => {
    const { required, type, id } = role;
    if (type === RoleTypesEnum.MANDATORY && required) {
      if (!members.some((m) => m.role.id === id)) {
        members.push(committeeMemberAdaptor(role));
      }
    }
  });

  members.map((m) => {
    const { type, minimumMembers, required, id } = m.role;
    if (type === RoleTypesEnum.MANDATORY && required && minimumMembers) {
      const roleCount = result.filter((m) => m.role.id === id)?.length;

      Array.from({
        length: minimumMembers > roleCount ? minimumMembers - roleCount : 0
      }).map(() => {
        result.push(committeeMemberAdaptor(m.role));
      });
    }
  });

  return result;
};

export const committeeMemberAdaptor = (role: IRole, modelId?: string, member?: IMemberDto): IMemberDto => {
  return {
    id: member?.id || null,
    roleId: member?.roleId || member?.role?.id || role?.id,
    role,
    employeeId: member?.employeeId || member?.employee?.id || null,
    employee: member?.employee || null,
    positionId: member?.positionId || (member?.position as string) || null,
    position: member?.employee?.position || null,
    legitimateDeputyId: member?.legitimateDeputyId || member?.legitimateDeputy?.id || null,
    legitimateDeputy: member?.legitimateDeputy || null,
    modelId: member?.modelId || modelId || null,
    required: role?.required || false,
    requiredToIdealModel: role?.requiredToIdealModel || false,
    order: role?.order || 0
  };
};
