import { Pipe, PipeTransform } from '@angular/core';
import { RruleFreqEnum, UnitDeclinationEnum } from '@common/enums';
import { unitDeclination } from '@common/utils/util';

@Pipe({ name: 'declination' })
export class DeclinationPipe implements PipeTransform {
  transform(value: number, freq?: RruleFreqEnum | UnitDeclinationEnum): string {
    switch (freq) {
      case RruleFreqEnum.WORKDAY_MONTHS:
        return `На ${value} рабочий день одного или нескольких месяцев`;
      case RruleFreqEnum.WORKDAY_HALF_YEAR:
        return 'Последний рабочий день полугодия';
      case RruleFreqEnum.WORKDAY_EACH_MONTH:
        return `На ${value} рабочий день каждого месяца`;
      case RruleFreqEnum.DAILY:
        return unitDeclination(value, UnitDeclinationEnum.DAY);
      case RruleFreqEnum.WEEKLY:
        return unitDeclination(value, UnitDeclinationEnum.WEEK);
      case RruleFreqEnum.MONTHLY:
        return unitDeclination(value, UnitDeclinationEnum.MONTH);
      case RruleFreqEnum.YEARLY:
        return unitDeclination(value, UnitDeclinationEnum.YEAR);
      default:
        return unitDeclination(value, freq);
    }
  }
}
