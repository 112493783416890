// FIXME: включить eslint и исправить ошибки
/* eslint-disable */

import { TitleCasePipe } from '@angular/common';
import moment from 'moment';
import { RruleFreqEnum, UnitDeclinationEnum } from '@common/enums';
import {
  ARRAY_LAST,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  MIN_COMMITTEE_INTERVAL,
  MONTHS_OPTIONS,
  TIME_FORMAT,
  WEEK_DAYS
} from '@common/constants';
import { getTimezoneOffset, unitDeclination } from '@common/utils/util';
import { DaysOffFormValue } from '@common/types/form-group.types';

export class RRuleModel {
  dtStart: string;
  dtstarttime?: string;
  timezone: string;
  duration: number;
  freq: RruleFreqEnum;
  interval: number;
  until!: string;
  byWeekday: string[];
  byMonth: number[];
  bySetPos: number[];
  byMonthDay: number[];
  excludeSaturdays: boolean;
  excludeSundays: boolean;
  excludeHolidays: boolean;
  shiftHolidays: boolean;
  shiftForward: boolean;
  excludeLunchTime: boolean;

  /**
   * Generate for form
   *
   * @param rrule
   */
  constructor(rrule: IRrule) {
    this.dtStart = rrule.dtStart && moment.utc(rrule.dtStart).tz(rrule.timezone).format(DATE_FORMAT);
    this.dtstarttime = rrule.dtStart && moment.utc(rrule.dtStart).tz(rrule.timezone).format(TIME_FORMAT);
    this.timezone = rrule.timezone;
    this.freq = rrule.freq;
    this.duration = rrule.duration;
    this.interval = rrule.interval;
    this.until = rrule.until && moment.utc(rrule.until).tz(rrule.timezone).format(DATE_TIME_FORMAT);
    this.byWeekday = rrule.byWeekday;
    this.byMonth = rrule.byMonth;
    this.bySetPos = rrule.bySetPos;
    this.byMonthDay = rrule.byMonthDay;
    this.excludeSaturdays = rrule.excludeSaturdays;
    this.excludeSundays = rrule.excludeSundays;
    this.excludeHolidays = rrule.excludeHolidays;
    this.shiftHolidays = rrule.shiftHolidays;
    this.shiftForward = rrule.shiftForward;
    this.excludeLunchTime = rrule.excludeLunchTime;
  }

  /**
   * Generate for api
   *
   * @param options
   * @returns
   */
  static omit(options: any): any {
    const hh_mm = options.dtstarttime?.split(':');
    const dtStart = moment.tz(options.dtStart, options.timezone).set({ h: +hh_mm[0], m: +hh_mm[1] });
    const result: any = {
      freq: options.freq,
      dtStart: moment.utc(dtStart).format(),
      timezone: options.timezone
    };
    if (options.interval) {
      result.interval = options.interval;
    }
    if (options.duration) {
      result.duration = options.duration;
    }
    if (options.until) {
      result.until = moment.tz(options.until, options.timezone).format();
    }
    if (options.byWeekday?.length) {
      result.byWeekday = options.byWeekday;
    }
    if (options.byMonth?.length) {
      result.byMonth = options.byMonth;
    }
    if (options.bySetPos?.length) {
      result.bySetPos = options.bySetPos;
    }
    if (options.byMonthDay?.length) {
      result.byMonthDay = options.byMonthDay;
    }
    result.excludeSaturdays = options.excludeSaturdays;
    result.excludeSundays = options.excludeSundays;
    result.excludeHolidays = options.excludeHolidays;
    result.shiftHolidays = options.shiftHolidays;
    result.shiftForward = options.shiftForward;
    result.excludeLunchTime = options.excludeLunchTime;
    result.byWorkDays = options.byWorkDays;
    return result;
  }

  static timeString(rrule: IRrule): string {
    const dtStart = moment.utc(rrule.dtStart).tz(rrule.timezone);
    return `${dtStart.format(TIME_FORMAT)}
      (UTC${getTimezoneOffset(dtStart.utcOffset())})`;
  }

  /**
   * Generate for rruleString
   *
   * @param rrule
   * @returns
   */
  static toString(rrule: IRrule): string {
    let result = '';
    switch (rrule.freq) {
      case RruleFreqEnum.WORKDAY_EACH_MONTH:
        const workDays = rrule.byWorkDays ? rrule.byWorkDays : [];
        return `На ${workDays[0] || 'N'} рабочий день каждого месяца`;
      case RruleFreqEnum.WORKDAY_HALF_YEAR:
        return 'Последний рабочий день полугодия';
      case RruleFreqEnum.WORKDAY_MONTHS:
        if (!rrule.byMonth) return '';
        const monthsStr = rrule.byMonth.reduce((acc, day, i) => {
          acc +=
            MONTHS_OPTIONS.find((o) => o.id === day)?.name + (i !== rrule.byMonth.length - 1 ? ', ' : '');
          return acc;
        }, '');

        return `${rrule.byWorkDays ? rrule.byWorkDays[0] : 'N'} рабочий день ${monthsStr}`;
      case RruleFreqEnum.DAILY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.byWeekday?.length) {
            result = 'Ежедневно по будням';
          } else {
            result = 'Ежедневно';
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(rrule.interval, UnitDeclinationEnum.DAY)}`;
        }
        break;
      case RruleFreqEnum.WEEKLY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.byWeekday?.length) {
            result = `Еженедельно по ${rrule.byWeekday
              .map((day) => WEEK_DAYS.find((el) => el.id === day).name)
              .join(', ')}`;
          } else {
            result = `Еженедельно по ${new TitleCasePipe().transform(
              moment.tz(rrule.dtStart, rrule.timezone).format('ddd')
            )}`;
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(
            rrule.interval,
            UnitDeclinationEnum.WEEK
          )}, по ${rrule.byWeekday.map((day) => WEEK_DAYS.find((el) => el.id === day).name).join(', ')}`;
        }
        break;
      case RruleFreqEnum.MONTHLY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.bySetPos?.length) {
            if (rrule.bySetPos[0] === ARRAY_LAST) {
              result = 'Ежемесячно, последнюю пятницу';
            } else {
              result = `Ежемесячно на ${rrule.bySetPos.map((pos) => `${pos}-й`).join(', ')} ${rrule.byWeekday
                .map((day) => WEEK_DAYS.find((el) => el.id === day).name)
                .join(', ')}`;
            }
          } else if (rrule.byMonthDay?.length) {
            if (rrule.byMonthDay[0] === ARRAY_LAST) {
              result = 'Ежемесячно, последний день месяца';
            } else {
              result = `Ежемесячно на ${rrule.byMonthDay.join(', ')} числа`;
            }
          } else {
            result = `Ежемесячно ${moment.tz(rrule.dtStart, rrule.timezone).format('DD')} числа`;
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(
            rrule.interval,
            UnitDeclinationEnum.MONTH
          )}, на ${rrule.byMonthDay.join(', ')} числа`;
        }
        break;
      case RruleFreqEnum.YEARLY:
        if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
          if (rrule.bySetPos?.length) {
            result = `Ежегодно, ${rrule.byMonth
              .map((m) => moment(m, 'M').format('MMMM'))
              .join(', ')}, ${rrule.bySetPos.map((pos) => `${pos}-й`).join(', ')} ${rrule.byWeekday
              .map((day) => WEEK_DAYS.find((el) => el.id === day).name)
              .join(', ')}`;
          } else if (rrule.byMonth?.length) {
            result = `Ежегодно, ${rrule.byMonth
              .map((m) => moment(m, 'M').format('MMMM'))
              .join(', ')}, ${rrule.byMonthDay.join(', ')} числа`;
          } else {
            result = `Ежегодно, ${moment.tz(rrule.dtStart, rrule.timezone).format('DD MMMM')}`;
          }
        } else {
          result = `Раз в ${rrule.interval} ${unitDeclination(
            rrule.interval,
            UnitDeclinationEnum.YEAR
          )}, ${rrule.byMonth.map((m) => moment(m, 'M').format('MMMM')).join(', ')}, ${rrule.byMonthDay.join(
            ', '
          )} числа`;
        }
        break;
    }
    return result;
  }
}

export interface IRrule extends DaysOffFormValue {
  dtStart?: string;
  byMonth?: number[];
  byMonthDay?: number[];
  byWorkDays?: number[];
  bySetPos?: number[];
  byWeekday?: string[];
  dtstarttime?: string;
  timezone?: string;
  duration?: number;
  freq: RruleFreqEnum;
  interval?: number;
  until?: string;
  excludeLunchTime?: boolean;
}

export const getFullRruleString = (rrule: IRrule) => {
  return RRuleModel.toString(rrule) + ', ' + RRuleModel.timeString(rrule);
};
