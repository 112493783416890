import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { RruleFreqEnum } from '@common/enums';

type MonthNumber = number;

type WorkDaysResponse = {
  fiveDaysWeek: Record<MonthNumber, number>;
  sixDaysWeek: Record<MonthNumber, number>;
};

@Injectable({
  providedIn: 'root'
})
export class WorkDaysService {
  private workDaysInfo: Record<MonthNumber, number>;

  constructor(private http: HttpClient) {}

  public load(): Observable<WorkDaysResponse> {
    return this.http
      .get<WorkDaysResponse>('api/Holidays/GetWorkDays')
      .pipe(tap((res) => (this.workDaysInfo = res)));
  }

  public getMinNumberByFreq(
    freq: RruleFreqEnum.WORKDAY_MONTHS | RruleFreqEnum.WORKDAY_EACH_MONTH,
    excludeSaturdays: boolean,
    selectedMonth: number[]
  ): number {
    const workDays = this.getWorkingDays(excludeSaturdays);
    if (freq === RruleFreqEnum.WORKDAY_EACH_MONTH) {
      return this.findMinDaysInYear(workDays);
    }

    if (freq === RruleFreqEnum.WORKDAY_MONTHS) {
      return this.findMinDaysInFewMonths(workDays, selectedMonth);
    }
  }

  private getWorkingDays(excludeSaturdays: boolean): Record<MonthNumber, number> {
    return this.workDaysInfo[excludeSaturdays ? 'fiveDaysWeek' : 'sixDaysWeek'];
  }

  private findMinDaysInFewMonths(workDays: Record<MonthNumber, number>, selectedMonths: number[]): number {
    let min: number | null = null;
    selectedMonths.map((m) => {
      const days = workDays[String(m)];
      if (min > days || !min) min = days;
    });

    return min ?? 1;
  }

  private findMinDaysInYear(workDays: Record<MonthNumber, number>): number {
    let min: number | null = null;

    Object.values(workDays).map((days: number) => {
      if (min > days || !min) min = days;
    });

    return min ?? 1;
  }

  public getLastWorkDayInMonth(months: number, excludeSaturdays: boolean): number {
    return this.getWorkingDays(excludeSaturdays)[months];
  }
}
