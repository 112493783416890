import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, Self } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import {
  asyncScheduler,
  catchError,
  combineLatest,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap
} from 'rxjs';
import moment, { tz } from 'moment';
import {
  IAttachmentDto,
  IEmployee,
  IEmployeeCurrentUserInfo,
  IEmployeeOption,
  IEmployeeRating,
  IEmployeeVote,
  IEventAgenda,
  IEventResolution,
  IMember,
  IOptGroup,
  IOption,
  IPermission
} from '@common/types';
import {
  AttachmentTypeEnum,
  CommitteeEventStatusEnum,
  EditorBlotsEnum,
  FuseDialogActionsEnum,
  ResolutionAudioRecordStatusEnum,
  ResolutionGeneralTypeEnum,
  ResolutionTypeEnum,
  RoleAccessesEnum,
  VoteEnum,
  VoteTypesEnum
} from '@common/enums';
import {
  AgendaItemAttachmentService,
  FileService,
  JitsuLoggerService,
  ProtocolService,
  UnsubscribeService,
  WebsocketService
} from '@common/services';
import {
  BUTTON_SPINNER_DIAMETER,
  DATE_FORMAT,
  DRAG_PLACEHOLDER_OFFSET,
  LoggingData,
  PreResolutionActions,
  ResolutionActions,
  SCROLL_STEP
} from '@common/constants';
import { clearFormArray, hasAccess } from '@common/utils/util';
import { SnackbarComponent } from '@common/shared';
import { EmployeeRatingsComponent } from '@common/dialogs/employee-ratings/employee-ratings.component';
import { FuseConfirmationService } from '@common/fuse/services/confirmation';
import {
  EmployeeListComponent,
  IEmployeeListProps
} from '@common/dialogs/employee-list/employee-list.component';
import { RatePreResolutionDialogComponent } from '@common/dialogs/rate-pre-resolution-dialog/rate-pre-resolution-dialog.component';
import { dateValidatorEqualOrBeforeToday, minTimeValidator } from '@common/utils/validators';
import { RecordRTCPromisesHandler } from 'recordrtc';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

const ATYPICAL_RESOLUTION: IOption = {
  id: 'atypical',
  name: '<p class="text-blue-500">Нетиповое решение</p>'
};

@Component({
  selector: 'com-resolution',
  styleUrls: ['resolution.component.scss'],
  templateUrl: './resolution.component.html',
  providers: [UnsubscribeService]
})
export class ResolutionComponent implements OnInit {
  @Input() resolutionType: ResolutionTypeEnum;
  @Input() committeeEventId: string;
  @Input() committeeEventStatus: CommitteeEventStatusEnum;
  @Input() currentUser: IEmployeeCurrentUserInfo;
  @Input() employees: IEmployeeOption[] = [];
  @Input() socketUpdate = false;
  @Input() isEventActive = false;
  @Input() editable = false;
  @Input() permission: IPermission;
  @Input() eventStarted = false;
  @Input() eventEnded = false;
  @Input() members: IMember[] = [];
  @Input() isCall = false;

  @Output() valueChange = new EventEmitter<IEventResolution[]>();
  @Output() documentShow = new EventEmitter<IAttachmentDto>();

  public formGroup = new FormGroup({
    resolutions: new FormArray<FormGroup>([])
  });
  public templateResolutions: (IOption | IOptGroup)[] = [];
  public responsibleOptions: IOption[] = [];
  public resolutions: IEventResolution[] = [];
  public materials: IAttachmentDto[];
  public isDeleteDisabled = false;
  public isSendDisabled = false;
  public currentDate: string = moment().format(DATE_FORMAT);
  public VoteTypes = VoteTypesEnum;
  public ResolutionTypesEnum = ResolutionGeneralTypeEnum;
  public SCROLL_STEP = SCROLL_STEP;
  public BUTTON_SPINNER_DIAMETER = BUTTON_SPINNER_DIAMETER;
  public placeholderHeight = 0;
  public isEditing = false;
  public canCreatePreResolution = false;
  public canRatePreResolution = false;
  public canViewPreliminarySolutionsProposedByOtherParticipants = false;
  public isPreliminaryStatus = false;
  public isRecording = false;

  protected readonly ResolutionTypeEnum = ResolutionTypeEnum;
  protected readonly CommitteeEventStatusEnum = CommitteeEventStatusEnum;
  protected readonly VoteEnum = VoteEnum;
  protected readonly ResolutionAudioRecordStatusEnum = ResolutionAudioRecordStatusEnum;

  private _agendaId: string;
  private recorder: RecordRTCPromisesHandler | null = null;

  constructor(
    private _protocolService: ProtocolService,
    private _agendaItemAttachmentService: AgendaItemAttachmentService,
    private _fileService: FileService,
    private _wsService: WebsocketService,
    private _fuseDialog: FuseConfirmationService,
    private _snackBar: MatSnackBar,
    private _matDialog: MatDialog,
    private _cdr: ChangeDetectorRef,
    private _jitsuLoggerService: JitsuLoggerService,
    private domSanitizer: DomSanitizer,
    @Self() private _unsubscribeService: UnsubscribeService
  ) {}

  @Input() set agenda(value: IEventAgenda) {
    this._agendaId = value.id;

    if (value.id) {
      this.templateResolutions = [
        {
          label: 'Типовые решения',
          options: value.templateResolutions.map((item, idx) => ({
            id: idx,
            name: item.value
          }))
        },
        ATYPICAL_RESOLUTION,
        ...(this.resolutionType === ResolutionTypeEnum.GENERAL
          ? [
              {
                label: 'Предварительные решения',
                options: value.eventResolutions
                  .filter(
                    (eventResolution) => eventResolution.resolutionType === ResolutionTypeEnum.PRELIMINARY
                  )
                  .map((item, idx) => ({
                    id: value.templateResolutions.length + idx,
                    name: this._genFromHTMLString(item.value),
                    nameOrign: item.value,
                    hint: `Предложено участником: ${item.createdBy?.shortName}, средняя оценка: ${
                      item.employeeRatings.length
                        ? (
                            item.employeeRatings.reduce((acc, cur) => acc + cur.rating, 0) /
                            item.employeeRatings.length
                          ).toFixed(1)
                        : '-'
                    }`
                  }))
              }
            ]
          : [])
      ];
    }
    this.resolutions = value.eventResolutions.filter(
      (eventResolution) => eventResolution.resolutionType === this.resolutionType
    );
    if (!this.materials) {
      this.materials = value.materials;
    }
  }

  public ngOnInit(): void {
    this.responsibleOptions = this.members.map((member) => ({
      id: member.committeeMember.employee.id,
      name: member.committeeMember.employee.isActive
        ? member.committeeMember.employee.fullName
        : member.committeeMember.employee.fullName + '(Заблокирован)'
    }));

    this.canCreatePreResolution = hasAccess(
      this.members.map((member) => ({
        ...member.committeeMember,
        memberAccesses: member.memberAccesses
      })),
      this.currentUser?.id,
      RoleAccessesEnum.CAN_CREATE_PRE_RESOLUTION
    );
    this.canRatePreResolution = hasAccess(
      this.members.map((member) => ({
        ...member.committeeMember,
        memberAccesses: member.memberAccesses
      })),
      this.currentUser?.id,
      RoleAccessesEnum.CAN_RATE_PRE_RESOLUTION
    );
    this.canViewPreliminarySolutionsProposedByOtherParticipants = hasAccess(
      this.members.map((member) => ({
        ...member.committeeMember,
        memberAccesses: member.memberAccesses
      })),
      this.currentUser?.id,
      RoleAccessesEnum.CAN_VIEW_PRELIMINARY_SOLUTIONS_PROPOSED_BY_OTHER_PARTICIPANTS
    );

    this.resolutions = this.resolutions.filter(
      (eventResolution) =>
        eventResolution.resolutionType === ResolutionTypeEnum.GENERAL ||
        this.canViewPreliminarySolutionsProposedByOtherParticipants ||
        eventResolution.createdBy.id === this.currentUser.id
    );

    if (
      (this.resolutionType === ResolutionTypeEnum.GENERAL && this.permission?.addResolution) ||
      (this.resolutionType === ResolutionTypeEnum.PRELIMINARY &&
        this.committeeEventStatus === CommitteeEventStatusEnum.PLANNED &&
        this.canCreatePreResolution &&
        !(this.formGroup.get('resolutions') as FormArray).controls.length &&
        !this.isCall)
    ) {
      (this.formGroup.get('resolutions') as FormArray).push(this._resolutionForm());
    }

    this.isPreliminaryStatus = this.committeeEventStatus === CommitteeEventStatusEnum.PLANNED;

    this.getResolutions();
    this._valueChanges();
  }

  public onSelectChanged(group: FormGroup, evt): void {
    group.get('selected').setValue(true);
    const option =
      evt.value === ATYPICAL_RESOLUTION
        ? ATYPICAL_RESOLUTION
        : this.templateResolutions
            .filter((templateResolution) => templateResolution !== ATYPICAL_RESOLUTION)
            .flatMap((templateResolution) => (templateResolution as IOptGroup).options)
            .find((item) => item.id === evt.value);
    if (evt.value !== 'atypical') {
      group.get('isAtypical').setValue(false);
      group.get('type').setValue(ResolutionGeneralTypeEnum.TYPICAL);
      if (option.hint) {
        group.get('value').setValue(option.nameOrign);
        group.get('isPreliminary').setValue(true);
      } else {
        group.get('valueTemp').setValue(option.name);
        const hasSpan = option.name.match(/<span.*?<\/span>/gi);
        if (hasSpan) {
          group.get('value').setValue('');
        } else {
          const div = document.createElement('div');
          div.insertAdjacentHTML('beforeend', option.name);
          const innerContent = div.querySelector('p');
          group.get('value').setValue(innerContent.innerText);
        }
      }
    } else {
      group.get('isAtypical').setValue(true);
      group.get('type').setValue(ResolutionGeneralTypeEnum.ATYPICAL);
      group.get('value').setValue('');
    }
  }

  public saveResolution(group: FormGroup): void {
    this.logEvent(
      this.resolutionType === ResolutionTypeEnum.GENERAL
        ? ResolutionActions.saveEditResolution
        : PreResolutionActions.saveEditResolution
    );
    group.get('value').updateValueAndValidity();
    if (group.valid) {
      if (this._agendaId) {
        this.createResolution(group).subscribe();
      } else {
        group.get('readonly').setValue(true);
      }
      this.isEditing = false;
    }
  }

  public editResolution(group: FormGroup): void {
    this.logEvent(
      this.resolutionType === ResolutionTypeEnum.GENERAL
        ? ResolutionActions.editResolution
        : PreResolutionActions.editPreResolution,
      {
        eventId: this.committeeEventId
      }
    );
    if (group.valid) {
      group.get('readonly').setValue(false);
      this.isEditing = true;
    }
  }

  public cancelEditResolution(group: FormGroup): void {
    group.get('readonly').setValue(true);
    group.get('value').setValue(group.get('valueTemp').value);
    group.get('resolutionKind').setValue(!!group.get('hintResponsibleId').value);
    group.get('responsibleId').setValue(group.get('hintResponsibleId').value);
    group.get('deadline').setValue(group.get('hintDeadline').value);
    this.isEditing = false;
  }

  public addResolution(): void {
    this.logEvent(
      this.resolutionType === ResolutionTypeEnum.GENERAL
        ? ResolutionActions.addResolution
        : PreResolutionActions.addPreResolution,
      {
        eventId: this.committeeEventId
      }
    );
    if (this.formGroup.get('resolutions').valid) {
      (this.formGroup.get('resolutions') as FormArray).push(this._resolutionForm());
      this.isEditing = true;
    }
  }

  private async initRecorder(): Promise<void> {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.recorder = new RecordRTCPromisesHandler(stream, {
      type: 'audio',
      disableLogs: true,
      mimeType: 'audio/wav'
    });
  }

  public async startRecording(): Promise<void> {
    await this.initRecorder();

    this.logEvent(
      this.resolutionType === ResolutionTypeEnum.GENERAL
        ? ResolutionActions.addResolution
        : PreResolutionActions.addPreResolution,
      {
        eventId: this.committeeEventId
      }
    );
    await this.recorder.startRecording();
    this.isRecording = true;
  }

  public async stopRecording(): Promise<void> {
    await this.recorder.stopRecording();
    const blob = await this.recorder.getBlob();
    this.recorder = null;
    this.isRecording = false;
    this.createResolution(
      this._resolutionForm({
        type: ResolutionGeneralTypeEnum.ATYPICAL,
        value: 'Решение в аудио-формате (нет текста)',
        audio: blob
      })
    ).subscribe();
  }

  public onAudioCLick(group: FormGroup): void {
    group.controls.isLoading.setValue(true);

    this._fileService
      .getFile(group.controls.recordFileId.value)
      .pipe(
        tap((audio) => {
          group.controls.audio.setValue(this.adaptorBlobToURL(audio));
          group.controls.isLoading.setValue(false);
          this._cdr.detectChanges();
          asyncScheduler.schedule(() =>
            (document.getElementById(group.controls.recordFileId.value) as HTMLAudioElement)?.play()
          );
        })
      )
      .subscribe();
  }

  private addAudioResolution(
    id: string,
    order: number,
    recordFileId: string,
    value: string,
    employeeVotes: IEmployeeVote[],
    audio?: SafeUrl
  ): void {
    (this.formGroup.get('resolutions') as FormArray).push(
      this._resolutionForm({
        id,
        order,
        type: ResolutionGeneralTypeEnum.ATYPICAL,
        readonly: true,
        value,
        recordFileId,
        employeeVotes,
        audio
      })
    );
  }

  private adaptorBlobToURL(audio: Blob): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(audio));
  }

  public deleteSolution(group: FormGroup): void {
    this.logEvent(ResolutionActions.openDialogDeleteResolution, {
      eventId: this.committeeEventId
    });
    this._fuseDialog
      .open({
        title: 'Удалить решение',
        message: 'Вы действительно хотите удалить выбранное решение?',
        actions: {
          confirm: { show: true, label: 'Удалить', color: 'warn' },
          cancel: { show: true, label: 'Отмена' }
        }
      })
      .afterClosed()
      .pipe(
        switchMap((res: FuseDialogActionsEnum) => {
          if (res === FuseDialogActionsEnum.CONFIRMED) {
            this.logEvent(
              this.resolutionType === ResolutionTypeEnum.GENERAL
                ? ResolutionActions.deleteResolution
                : PreResolutionActions.deletePreResolution,
              { eventId: this.committeeEventId }
            );
            const idx = (this.formGroup.get('resolutions') as FormArray).controls.findIndex(
              (ctrl) => ctrl === group
            );
            this.isEditing = false;
            if (!group.get('id').value) {
              (this.formGroup.get('resolutions') as FormArray).removeAt(idx);
              return of(res);
            }
            this.isDeleteDisabled = true;
            return this._protocolService.deleteProtocolEventResolution(
              group.get('id').value,
              this.committeeEventId
            );
          }
          return of(res);
        }),
        filter((res) => !res),
        takeUntil(this._unsubscribeService)
      )
      .subscribe(() => {
        const idx = (this.formGroup.get('resolutions') as FormArray).controls.findIndex(
          (ctrl) => ctrl === group
        );
        this._snackBar.openFromComponent(SnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 3000,
          data: {
            icon: 'heroicons_outline:exclamation',
            iconClass: 'text-red-500',
            title: 'Удаление решения!',
            message: 'Решение по выбранному вопросу повестки удалено.'
          },
          panelClass: 'committees-app'
        });
        if (!this.socketUpdate) {
          (this.formGroup.get('resolutions') as FormArray).removeAt(idx);
          this.isDeleteDisabled = false;
        }
      });
  }

  public sendVote(type: VoteTypesEnum, group: FormGroup): void {
    this.voteLogging(group.get('isUserAbstained').value, type, group);
    group.get('voteWait').setValue(true);
    this._protocolService
      .setVote(
        this.committeeEventId,
        group.get('id').value,
        this.currentUser.id,
        type === VoteTypesEnum.BEHIND ? VoteEnum.BEHIND : VoteEnum.AGAINST
      )
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe();
  }

  private voteLogging(isUserAbstained: boolean, type: VoteTypesEnum, group: FormGroup): void {
    if (isUserAbstained) {
      this.logEvent(
        type === VoteTypesEnum.BEHIND
          ? ResolutionActions.voteForResolution
          : ResolutionActions.voteAgainstResolution,
        this.resolutionLogMapper(group)
      );
    } else {
      this.logEvent(ResolutionActions.voteAbstainedResolution, this.resolutionLogMapper(group));
    }
  }

  private resolutionLogMapper(group: FormGroup): LoggingData {
    return {
      eventId: this.committeeEventId,
      resolutionId: group.get('id').value
    };
  }

  public onRatePreResolutionClick(group: FormGroup): void {
    const { id } = group.value;
    const dialogRef = this._matDialog.open(RatePreResolutionDialogComponent, {
      panelClass: 'committees-app',
      data: group.value.userRating
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter((data) => data),
        tap((data) => {
          this.logEvent(PreResolutionActions.saveRatingPreResolution, {
            eventId: this.committeeEventId,
            id,
            data
          });
        }),
        switchMap((data) =>
          this._protocolService.sendProtocolEventRating(this.committeeEventId, id, data).pipe(
            tap(() => {
              group.controls.userRating.setValue(data);
              this.resolutions = this.resolutions.map((resolution) =>
                resolution.id === id
                  ? {
                      ...resolution,
                      employeeRatings: resolution.employeeRatings.some(
                        (employeeRating) => employeeRating.employee.id === this.currentUser?.id
                      )
                        ? resolution.employeeRatings.map((employeeRating) =>
                            employeeRating.employee.id === this.currentUser?.id
                              ? { ...employeeRating, rating: data }
                              : employeeRating
                          )
                        : [...resolution.employeeRatings, { employee: this.currentUser, rating: data }]
                    }
                  : resolution
              );
            })
          )
        ),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  public showEmployeeRatings(data: IEmployeeRating[]): void {
    this._matDialog.open(EmployeeRatingsComponent, {
      data
    });
  }

  public onOpenEmployeeListDialog(
    employeeVotes: IEmployeeVote[],
    title: string,
    voteType: VoteEnum | null = null
  ): void {
    const employees = this.employeesMapperForDialog(employeeVotes, voteType, this.members);
    if (employees.length !== 0) {
      this._matDialog.open(EmployeeListComponent, {
        data: {
          employees,
          title
        } as IEmployeeListProps
      });
    }
  }

  private employeesMapperForDialog(
    employeeVotes: IEmployeeVote[],
    voteType: VoteEnum | null,
    members: IMember[]
  ): IEmployee[] {
    if (voteType !== null) {
      return employeeVotes
        .filter((employeeVote) => employeeVote.voteType === voteType)
        .map(({ employee }) => employee);
    }
    const employeeVoteIds = employeeVotes.map(({ employee }) => employee.id);
    return members
      .filter(
        ({ committeeMember, memberAccesses, delegateTo }) =>
          !employeeVoteIds.includes(committeeMember.employee.id) &&
          memberAccesses.includes(RoleAccessesEnum.VOTE_RESOLUTION) &&
          !delegateTo
      )
      .map(({ committeeMember }) => committeeMember.employee);
  }

  public onMaterialUpload(event: Event, eventResolutionId: string): void {
    this.logEvent(PreResolutionActions.uploadMaterialPreResolution, {
      eventId: this.committeeEventId,
      eventResolutionId
    });
    const target = event.target as HTMLInputElement;
    if (target.files.length) {
      const file = target.files[0];
      if (file.size === 0) {
        this._snackBar.openFromComponent(SnackbarComponent, {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          duration: 3000,
          data: {
            icon: 'heroicons_outline:exclamation',
            iconClass: 'text-red-500',
            title: 'Загрузка файлов без содержимого недоступна!',
            message: 'Вы пытаетесь прикрепить файл без содержимого.'
          },
          panelClass: 'committees-app'
        });
        target.value = '';
        return;
      }
      this._agendaItemAttachmentService
        .createAgendaItemAttachment({
          committeeEventId: this.committeeEventId,
          agendaItemId: this._agendaId,
          required: true,
          attachmentType: AttachmentTypeEnum.FILE,
          link: null,
          file: target.files[0],
          eventResolutionId
        })
        .pipe(
          catchError(() => of(null)),
          takeUntil(this._unsubscribeService)
        )
        .subscribe((material) => {
          this.materials = [...this.materials, material];
        });
    }
  }

  public onDownloadAttachmentClick(material: IAttachmentDto): void {
    this.logEvent(PreResolutionActions.downloadMaterialPreResolution, {
      eventId: this.committeeEventId,
      materialName: material.fileName
    });
    this._fileService.downloadFile(material.fileId, material.fileName);
  }

  public onDeleteAttachmentClick(material: IAttachmentDto): void {
    this.logEvent(PreResolutionActions.removeMaterialPreResolution, {
      eventId: this.committeeEventId,
      materialName: material.fileName
    });
    this._fuseDialog
      .open({
        title: 'Удалить материал',
        message: 'Вы действительно хотите удалить выбранный материал?',
        actions: {
          confirm: { show: true, label: 'Удалить', color: 'warn' },
          cancel: { show: true, label: 'Отмена' }
        }
      })
      .afterClosed()
      .pipe(
        switchMap((res: FuseDialogActionsEnum) => {
          if (res === FuseDialogActionsEnum.CONFIRMED) {
            return this._agendaItemAttachmentService.deleteAgendaItemAttachment(
              this.committeeEventId,
              material.id
            );
          }
          return of(res);
        }),
        catchError((err: HttpErrorResponse) => {
          this._snackBar.openFromComponent(SnackbarComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            data: {
              icon: 'heroicons_outline:exclamation',
              iconClass: 'text-red-500',
              title: 'Удаление невозможно',
              message: 'Материал был удалён ранее'
            },
            panelClass: 'committees-app'
          });
          throw new Error(`Can't delete. Details: ${err}`);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((res) => {
        if (res !== FuseDialogActionsEnum.CANCELLED) {
          this.materials = this.materials.filter((item) => item !== material);
        }
      });
  }

  public onResolutionDragStart(event: CdkDragStart): void {
    this.placeholderHeight = event.source.element.nativeElement.offsetHeight + DRAG_PLACEHOLDER_OFFSET;
    this._cdr.detectChanges();
  }

  public onResolutionDrop(event: CdkDragDrop<any[]>): void {
    this.isEditing = true;
    moveItemInArray(this.formGroup.controls.resolutions.controls, event.previousIndex, event.currentIndex);
    this.formGroup.controls.resolutions.controls.forEach((control, index) => {
      control.patchValue({ order: index });
    });
    this._protocolService
      .createSomeProtocolEventResolutions([
        ...this.formGroup.controls.resolutions.getRawValue().map((resolution, index) => ({
          ...resolution,
          order: index,
          agendaItemId: this._agendaId,
          committeeEventId: this.committeeEventId,
          deadline: resolution.deadline && moment(resolution.deadline).tz(tz.guess()).format()
        }))
      ])
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this.isEditing = false;
      });
  }

  private getResolutions(): void {
    if (this.resolutions.length) {
      const audioFiles: Record<string, SafeUrl> = {};
      this.formGroup.get('resolutions').value.map(({ recordFileId, audio }) => {
        if (recordFileId) {
          audioFiles[recordFileId] = audio;
        }
      });

      clearFormArray(this.formGroup.get('resolutions') as FormArray);
      this.resolutions.forEach((resolution, idx) => {
        if (resolution.recordFileId) {
          this.addAudioResolution(
            resolution.id,
            idx,
            resolution.recordFileId,
            resolution.value || '-',
            resolution.employeeVotes,
            audioFiles[resolution.recordFileId]
          );
          return;
        }
        (this.formGroup.get('resolutions') as FormArray).push(
          this._resolutionForm({
            id: resolution.id,
            value: resolution.value,
            order: idx,
            readonly: true,
            type: resolution.type,
            resolutionType: resolution.resolutionType,
            resolutionKind: resolution.resolutionKind,
            responsibleId: resolution.responsible?.id,
            deadline: (resolution.deadline && moment.utc(resolution.deadline).local()) || null,
            employeeVotes: resolution.employeeVotes,
            userRating: resolution.employeeRatings.find(
              (employeeRating) => employeeRating.employee.id === this.currentUser?.id
            )?.rating
          })
        );
      });
    }
  }

  private updateResolution(resolution: IEventResolution): void {
    const formArray = this.formGroup.get('resolutions') as FormArray;
    const order = formArray.value.findIndex((r) => r.id === resolution.id);

    formArray.removeAt(order);

    formArray.insert(
      order,
      this._resolutionForm({
        id: resolution.id,
        value: resolution.value,
        order,
        readonly: true,
        type: resolution.type,
        resolutionType: resolution.resolutionType,
        resolutionKind: resolution.resolutionKind,
        responsibleId: resolution.responsible?.id,
        deadline: (resolution.deadline && moment.utc(resolution.deadline).local()) || null,
        employeeVotes: resolution.employeeVotes,
        userRating: resolution.employeeRatings.find(
          (employeeRating) => employeeRating.employee.id === this.currentUser?.id
        )?.rating
      })
    );
  }

  private _valueChanges(): void {
    this._wsService.resolutionVote$
      .pipe(distinctUntilChanged(), filter(Boolean), takeUntil(this._unsubscribeService))
      .subscribe((resolution) => {
        (this.formGroup.get('resolutions') as FormArray).controls.forEach((ctrl) => {
          if (ctrl.get('id').value === resolution.id) {
            const behindCount = this._countVote(resolution.employeeVotes, VoteEnum.BEHIND);
            const againstCount = this._countVote(resolution.employeeVotes, VoteEnum.AGAINST);
            const abstainedCount =
              this.members.filter(
                (m) => m.memberAccesses.includes(RoleAccessesEnum.VOTE_RESOLUTION) && !m.delegateTo
              ).length -
              behindCount -
              againstCount;

            const isUserBehind = this._isVote(resolution.employeeVotes, VoteEnum.BEHIND);
            const isUserAgainst = this._isVote(resolution.employeeVotes, VoteEnum.AGAINST);
            const isUserAbstained = !isUserBehind && !isUserAgainst;

            ctrl.get('voteWait').setValue(false);
            ctrl.get('employeeVotes').setValue(resolution.employeeVotes);
            ctrl.get('behindCount').setValue(behindCount);
            ctrl.get('againstCount').setValue(againstCount);
            ctrl.get('abstainedCount').setValue(abstainedCount);
            ctrl.get('isUserBehind').setValue(isUserBehind);
            ctrl.get('isUserAgainst').setValue(isUserAgainst);
            ctrl.get('isUserAbstained').setValue(isUserAbstained);

            const findIndex = this.resolutions.findIndex((r) => r.id === resolution.id);
            this.resolutions[findIndex].employeeVotes = resolution.employeeVotes;
            this.resolutions[findIndex].behindMembersCount = behindCount;
            this.resolutions[findIndex].againstMembersCount = againstCount;
            this.resolutions[findIndex].abstainedMembersCount = abstainedCount;
            this.resolutions[findIndex].isUserBehind = isUserBehind;
            this.resolutions[findIndex].isUserAgainst = isUserAgainst;
            this.resolutions[findIndex].isUserAbstained = isUserAbstained;
          }
        });
      });

    this._wsService.newResolution$
      .pipe(distinctUntilChanged(), filter(Boolean), takeUntil(this._unsubscribeService))
      .subscribe((data) => {
        const resolution = data.resolution;
        const hasAccessUsers = data.hasAccessUsers;
        if (this.checkResolutionForCurrent(resolution) && hasAccessUsers.includes(this.currentUser?.id)) {
          if (!this.resolutions.map((item) => item.id).includes(resolution.id)) {
            this.resolutions.push(resolution);
          }
          const savedResolutions = (this.formGroup.get('resolutions') as FormArray).controls.filter(
            (ctrl) => ctrl.get('id').value
          );

          if (!savedResolutions.map((crtl) => crtl.get('id').value).includes(resolution.id)) {
            (this.formGroup.get('resolutions') as FormArray).controls.splice(
              savedResolutions.length,
              0,
              this._resolutionForm({
                id: resolution.id,
                value: resolution.value,
                order: resolution.order,
                readonly: true,
                type: resolution.type,
                resolutionType: resolution.resolutionType,
                resolutionKind: resolution.resolutionKind,
                responsibleId: resolution.responsible?.id,
                deadline: (resolution.deadline && moment.utc(resolution.deadline).local()) || null,
                abstainedMembers: resolution.abstainedMembers,
                againstMembers: resolution.againstMembers,
                behindMembers: resolution.behindMembers,
                abstainedCount: resolution.abstainedMembersCount,
                againstCount: resolution.againstMembersCount,
                behindCount: resolution.behindMembersCount
              })
            );
          }
          asyncScheduler.schedule(() => this.getResolutions());
          this.valueChange.emit(this.resolutions);
        }
        this.isEditing = false;
      });
    this._wsService.updateResolution$
      .pipe(
        distinctUntilChanged(),
        filter((resolution) => resolution && !!this._agendaId),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((resolution) => {
        if (this.checkResolutionForCurrent(resolution)) {
          this.resolutions = this.resolutions.map((item) => {
            if (item.id === resolution.id) {
              return resolution;
            }
            return item;
          });
          this.updateResolution(resolution);
          this.valueChange.emit(this.resolutions);
        }
        this.isEditing = false;
      });
    this._wsService.deleteResolution$
      .pipe(distinctUntilChanged(), filter(Boolean), takeUntil(this._unsubscribeService))
      .subscribe((resolution) => {
        this.isEditing = false;
        this.isDeleteDisabled = false;
        if (
          resolution.agendaItemId === this._agendaId &&
          this.resolutions.find((item) => item.id === resolution.protocolResolutionId)?.resolutionType ===
            this.resolutionType &&
          resolution.committeeEventId === this.committeeEventId
        ) {
          this.resolutions = this.resolutions.filter((item) => item.id !== resolution.protocolResolutionId);
          (this.formGroup.get('resolutions') as FormArray).controls = (
            this.formGroup.get('resolutions') as FormArray
          ).controls.filter((ctrl) => ctrl.get('id').value !== resolution.protocolResolutionId);
          this.valueChange.emit(this.resolutions);
        }
      });
  }

  private checkResolutionForCurrent(resolution: any): boolean {
    return (
      resolution.agendaItemId === this._agendaId &&
      resolution.resolutionType === this.resolutionType &&
      resolution.committeeEventId === this.committeeEventId &&
      (resolution.createdById === this.currentUser.id ||
        this.canViewPreliminarySolutionsProposedByOtherParticipants ||
        resolution.resolutionType === ResolutionTypeEnum.GENERAL)
    );
  }

  private createResolution(group: FormGroup): Observable<IEventResolution> {
    this.isSendDisabled = true;
    const item = group.value;
    return this._protocolService[item.id ? 'updateProtocolEventResolution' : 'createProtocolEventResolution'](
      {
        id: item.id || null,
        value: item.value,
        type: item.type,
        resolutionType: item.resolutionType,
        resolutionKind: +item.resolutionKind,
        order: item.order,
        responsibleId: item.responsibleId,
        deadline:
          item.deadline &&
          moment(item.time ? item.deadline : `${item.deadline}T23:59:59`)
            .local()
            .format(),
        agendaItemId: this._agendaId,
        committeeEventId: this.committeeEventId,
        ...(item.audio ? { recordFile: item.audio } : {})
      }
    )
      .pipe(
        tap((res) => {
          this.isSendDisabled = false;
          this._snackBar.openFromComponent(SnackbarComponent, {
            horizontalPosition: 'center',
            verticalPosition: 'top',
            duration: 3000,
            data: {
              title: 'Решение сохранено!',
              message: 'Решение по выбранному вопросу повестки сохранено.'
            },
            panelClass: 'committees-app'
          });
          if (!this.socketUpdate) {
            const idx = (this.formGroup.get('resolutions') as FormArray).controls.findIndex(
              (ctrl) => ctrl === group
            );
            (this.formGroup.get('resolutions') as FormArray).at(idx).patchValue({
              id: res.id,
              value: res.value,
              order: res.order,
              readonly: true,
              type: res.type,
              resolutionType: res.resolutionType,
              abstainedCount: res.abstainedMembersCount,
              againstCount: res.againstMembersCount,
              behindCount: res.behindMembersCount
            });
          }
        })
      )
      .pipe(takeUntil(this._unsubscribeService));
  }

  private _resolutionForm(item?: any): FormGroup {
    let order = 0;
    if ((this.formGroup?.get('resolutions') as FormArray).controls.length) {
      order = (this.formGroup.get('resolutions') as FormArray).controls.length;
    }
    const behindCount = item ? this._countVote(item.employeeVotes, VoteEnum.BEHIND) : 0;
    const againstCount = item ? this._countVote(item.employeeVotes, VoteEnum.AGAINST) : 0;
    const abstainedCount =
      this.members.filter((m) => m.memberAccesses.includes(RoleAccessesEnum.VOTE_RESOLUTION) && !m.delegateTo)
        .length -
      behindCount -
      againstCount;

    const isUserBehind = (item && this._isVote(item.employeeVotes, VoteEnum.BEHIND)) || false;
    const isUserAgainst = (item && this._isVote(item.employeeVotes, VoteEnum.AGAINST)) || false;
    const isUserAbstained = !isUserBehind && !isUserAgainst;
    const ctrl = new FormGroup({
      id: new FormControl<string>(item?.id || null),
      isAtypical: new FormControl<boolean>(false),
      selected: new FormControl<boolean>(item?.value || false),
      readonly: new FormControl<boolean>(item?.readonly || false),
      isPreliminary: new FormControl<boolean>(false),
      valueTemp: new FormControl<string>(item?.value || null),
      value: new FormControl<string>(item?.value || null, item?.audio ? [] : [Validators.required]),
      type: new FormControl<number>(item?.type),
      resolutionType: new FormControl<ResolutionTypeEnum>(item?.resolutionType || this.resolutionType),
      resolutionKind: new FormControl<boolean>({
        value: !!item?.resolutionKind || false,
        disabled: item?.readonly
      }),
      responsibleId: new FormControl<string>(
        {
          value: item?.responsibleId || null,
          disabled: item?.readonly
        },
        item?.responsibleId ? [Validators.required] : []
      ),
      deadline: new FormControl<string>(item?.deadline || null),
      date: new FormControl<string>(
        {
          value: item?.deadline?.format().split('T')[0],
          disabled: item?.readonly
        },
        [dateValidatorEqualOrBeforeToday()]
      ),
      time: new FormControl<string>({
        value: item?.deadline?.seconds() ? null : item?.deadline?.format().split('T')[1].slice(0, 5),
        disabled: item?.readonly
      }),
      hintResponsibleId: new FormControl<string>(item?.responsibleId || null),
      hintDeadline: new FormControl<string>(item?.deadline || null),
      order: new FormControl<number>(item?.order || order || 0),
      voteWait: new FormControl<boolean>(false),
      behindCount: new FormControl<number>(behindCount),
      againstCount: new FormControl<number>(againstCount),
      abstainedCount: new FormControl<number>(abstainedCount),
      isUserBehind: new FormControl<boolean>(isUserBehind),
      isUserAgainst: new FormControl<boolean>(isUserAgainst),
      isUserAbstained: new FormControl<boolean>(isUserAbstained),
      userRating: new FormControl<number>(item?.userRating || null),
      employeeVotes: new FormControl<IEmployeeVote[]>(item?.employeeVotes || []),
      audio: new FormControl<SafeUrl | undefined>(item?.audio),
      recordFileId: new FormControl<string | undefined>(item?.recordFileId),
      isLoading: new FormControl<boolean>(false)
    });

    ctrl
      .get('readonly')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe((value) => {
        if (value) {
          ctrl.get('resolutionKind').disable();
          ctrl.get('responsibleId').disable();
          ctrl.get('date').disable();
          ctrl.get('time').disable();
        } else {
          ctrl.get('resolutionKind').enable();
          ctrl.get('responsibleId').enable();
          ctrl.get('date').enable();
          ctrl.get('time').enable();
        }
      });

    ctrl
      .get('resolutionKind')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe((value) => {
        if (value) {
          ctrl.get('responsibleId').setValidators(Validators.required);
        } else {
          ctrl.get('responsibleId').clearValidators();
          ctrl.get('responsibleId').reset();
          ctrl.get('deadline').reset();
        }

        ctrl.updateValueAndValidity();
      });

    ctrl
      .get('date')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe((date) => {
        const timeCtrl = ctrl.get('time');
        if (!date) {
          timeCtrl.reset();
          timeCtrl.disable();
        } else {
          timeCtrl.enable();
          const { time } = ctrl.controls;
          time.setValidators([minTimeValidator(tz.guess(), ctrl.value.date)]);
          time.updateValueAndValidity({ emitEvent: false, onlySelf: true });
        }
      });

    combineLatest([ctrl.get('date').valueChanges, ctrl.get('time').valueChanges.pipe(startWith(null))])
      .pipe(takeUntil(this._unsubscribeService))
      .subscribe(([date, time]) => {
        ctrl.get('deadline').setValue(date ? `${date}${time ? `T${time}` : ''}` : null);
      });

    return ctrl;
  }

  private _isVote(items: any[], voteType: VoteEnum): boolean {
    return items
      ?.filter((vote) => vote.voteType === voteType)
      .reduce((acc, vote) => acc || vote.employee.id === this.currentUser?.id, false);
  }

  private _countVote(items: any[], voteType: number): number {
    return items?.filter((vote) => vote.voteType === voteType).length ?? 0;
  }

  private _genFromHTMLString(htmlString: string): string {
    const result = [];
    const div = document.createElement('div');
    div.insertAdjacentHTML('beforeend', htmlString);
    const paragraphs = div.querySelectorAll('p');
    const blocks = paragraphs.length ? paragraphs : [div];
    blocks.forEach((innerContent) => {
      if (innerContent) {
        const content = innerContent.innerHTML;
        const textArr = content.split(/<span class="ql-editable-blot".*?<\/span>/gi);
        const spans = Array.from(innerContent.querySelectorAll('span'));
        textArr.forEach((item, idx) => {
          if (item) {
            result.push(item.trim());
          }
          const span = spans[idx] as HTMLSpanElement;
          if (span) {
            if (span.getAttribute('type') === EditorBlotsEnum.USER) {
              span.innerText = span.getAttribute('name');
            }
            result.push(span.outerHTML);
          }
        });
      } else {
        result.push(div.textContent.trim());
      }
    });

    return `<p>${result.join(' ')}</p>`;
  }

  private logEvent(action: ResolutionActions | PreResolutionActions, data?: LoggingData): void {
    this._jitsuLoggerService.logEvent(action, data);
  }
}
