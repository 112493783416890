import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { asyncScheduler, filter, map, pairwise, skip, Subject, takeUntil, tap } from 'rxjs';
import 'moment-timezone';
import moment from 'moment/moment';
import { RruleFreqEnum, RruleRepeatEnum } from '@common/enums';
import { TimezoneService, UnsubscribeService } from '@common/services';
import { DaysOffFormValue, IOption, IRrule, IRruleFormGroup, RRuleModel } from '@common/types';
import {
  ARRAY_LAST,
  DATE_FORMAT,
  DAYS_IN_MONTH,
  DAYS_IN_WEEK,
  MAX_COMMITTEE_DURATION,
  MIN_COMMITTEE_DURATION,
  MIN_COMMITTEE_INTERVAL,
  MONTHS_OPTIONS,
  WEEK_CODES,
  WEEK_DAYS
} from '@common/constants';
import { Days } from 'rrule/dist/esm/src/rrule';
import { dateValidator, minTimeValidator } from '@common/utils/validators';
import { isEqual } from 'lodash';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';
import { WorkDaysService } from '@common/services/work-days.service';

@Component({
  selector: 'com-rrule',
  templateUrl: './rrule.component.html',
  styleUrls: ['./rrule.component.scss'],
  providers: [UnsubscribeService]
})
export class RruleComponent extends FormAbstractionComponent implements OnInit, OnChanges, AfterViewInit {
  @Output() getFormValue = new EventEmitter<() => IRrule>();

  // TODO: добавить тип RruleFormValue
  @Input() formValue: IRrule;
  @Input() isEdit = false;
  @Output() checkTimeValidation = new EventEmitter<() => void>();

  public RepeatEnum = RruleRepeatEnum;
  public FreqEnum = RruleFreqEnum;
  public currentDate: string = moment().format(DATE_FORMAT);

  protected MONTHS_OPTIONS = MONTHS_OPTIONS;

  public workDaysFreq = [
    RruleFreqEnum.WORKDAY_MONTHS,
    RruleFreqEnum.WORKDAY_EACH_MONTH,
    RruleFreqEnum.WORKDAY_HALF_YEAR
  ];
  public workDaySelectedMonths = new FormControl<number[]>([]);
  public workDayNumber = new FormControl<number>(null, [Validators.min(1), Validators.max(23)]);
  public maxWorkDayValidationNumber = 1;

  public formGroup: FormGroup<IRruleFormGroup> = new FormGroup<IRruleFormGroup>(
    {
      dtStart: new FormControl<string>(null, [Validators.required, dateValidator({ min: this.currentDate })]),
      dtstarttime: new FormControl<string>(null, Validators.required),
      duration: new FormControl<number>(null, [
        Validators.required,
        Validators.min(MIN_COMMITTEE_DURATION),
        Validators.max(MAX_COMMITTEE_DURATION)
      ]),
      timezone: new FormControl<string>(moment.tz.guess(), Validators.required),
      interval: new FormControl<number>(null, [Validators.required, Validators.min(MIN_COMMITTEE_INTERVAL)]),
      repeat: new FormControl<RruleRepeatEnum>({ value: null, disabled: true }, Validators.required),
      freq: new FormControl<RruleFreqEnum>(null, Validators.required),
      byWeekday: new FormControl<string[]>(null),
      byMonth: new FormControl<number[]>(null),
      bySetPos: new FormControl<number[]>(null),
      byMonthDay: new FormControl<number[]>(null),
      byWorkDays: new FormControl<number[]>([]),
      excludeLunchTime: new FormControl<boolean>(true)
    },
    []
  );
  public weekDays: IOption[] = WEEK_DAYS;
  public monthdayOptions: IOption[] = Array.from({ length: DAYS_IN_MONTH }).map((_, index) => ({
    id: index + 1,
    name: String(index + 1)
  }));
  public durationTimes: IOption[] = [
    { name: '20', id: 20 },
    { name: '50', id: 50 },
    { name: '80', id: 80 },
    { name: '110', id: 110 }
  ];
  public monthOptions: IOption[] = moment.months().map((month, index) => ({ id: index + 1, name: month }));

  // TODO init value in two place, its not best solution
  public daysOffForm: DaysOffFormValue = {
    excludeSaturdays: false,
    excludeSundays: false,
    excludeHolidays: false
  };

  public updateDaysOffForm$: Subject<DaysOffFormValue> = new Subject<DaysOffFormValue>();

  public lunchStartMinutes = 720;
  public lunchEndMinutes = 780;

  constructor(
    protected readonly timezoneService: TimezoneService,
    public workDaysService: WorkDaysService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
  }

  public ngOnInit(): void {
    this._valueChanges();
    this.emitFormMethods();
    this.checkTimeValidation.emit(this.checkDateTime.bind(this));
    this.getFormValue.emit(this._rruleFormMapper.bind(this));
    this.workdayEachMonthChangeSub();
    this.selectedWorkDayMonthChangeSub();
  }

  ngAfterViewInit(): void {
    this.setValidMethodCheck(this.isWorkdaysValid.bind(this));
  }

  public checkDateTime(): void {
    this._checkDateTime();
    this.formGroup.get('dtStart').markAsTouched();
    this.formGroup.get('dtStart').updateValueAndValidity();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('formValue' in changes && this.formValue) {
      asyncScheduler.schedule(() => this.setWorkDays());

      if (this.formValue.dtStart) {
        if (moment(this.formValue.dtStart).isBefore(this.currentDate)) {
          this.currentDate = moment(this.formValue.dtStart).format(DATE_FORMAT);
          this.formGroup
            .get('dtStart')
            .setValidators([Validators.required, dateValidator({ min: this.currentDate })]);
        }
        if (moment(this.formValue.dtStart).isSame(moment(), 'date')) {
          this.formGroup.get('dtStart').updateValueAndValidity();
        }
        this.formGroup.get('repeat').enable({ emitEvent: false });
      }
      this.formGroup.patchValue(
        {
          ...new RRuleModel(this.formValue),
          repeat: this._getRepeatValue(this.formValue)
        },
        { emitEvent: false }
      );

      asyncScheduler.schedule(() => {
        const { excludeSaturdays, excludeSundays, excludeHolidays, shiftHolidays, shiftForward } =
          this.formValue;

        this.updateDaysOffForm$.next({
          excludeSaturdays,
          excludeSundays,
          excludeHolidays,
          shiftHolidays,
          shiftForward
        });
      });
    }
  }

  public onDaysOffValueChange(daysOffForm: DaysOffFormValue): void {
    if (
      this.daysOffForm &&
      this.formValue &&
      Object.entries(daysOffForm).some(([key, value]) => value !== this.formValue[key])
    ) {
      this._checkDateTime();
    }
    this.daysOffForm = daysOffForm;
    const freq = this.formGroup.controls.freq.value;
    if (this.workDaysFreq.includes(freq)) {
      this.checkValidationWorkDays(freq);
      this.workDayNumber.updateValueAndValidity();
    }
  }

  private _valueChanges(): void {
    this.formGroup
      .get('dtStart')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe((date) => {
        this.checkByWeekDay(date);
        this.checkSetPosition(date);
        this._checkDateTime();
      });
    this.formGroup
      .get('dtstarttime')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('timezone')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('duration')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('byMonth')
      .valueChanges.pipe(skip(this.isEdit ? 1 : 0), takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('byMonthDay')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('interval')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('excludeLunchTime')
      .valueChanges.pipe(takeUntil(this._unsubscribeService))
      .subscribe(() => {
        this._checkDateTime();
      });
    this.formGroup
      .get('repeat')
      .valueChanges.pipe(
        pairwise(),
        map(([prev, value]) => (!this.isEdit || prev in RruleRepeatEnum) && prev !== value && value),
        filter((value) => value in RruleRepeatEnum),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value: RruleRepeatEnum) => {
        this.checkValidationWorkDays(this.mapperFreq(value));

        this._resetValidation();
        this._checkDateTime();
        const dtStart = moment(this.formGroup.get('dtStart').value);
        const monthlyWeekday = Math.floor((dtStart.date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);
        this.formGroup.patchValue({
          freq: this.calculateFreq(value),
          interval: MIN_COMMITTEE_INTERVAL,
          byWeekday:
            value === RruleRepeatEnum.DAILY_WEEKDAY
              ? [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR].map((day) => day.toString())
              : [RruleRepeatEnum.MONTHLY_WEEKDAY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
                ? [WEEK_CODES[dtStart.weekday()]]
                : value === RruleRepeatEnum.MONTHLY_LAST_FRIDAY
                  ? [Days.FR.toString()]
                  : null,
          byMonthDay: value === RruleRepeatEnum.MONTHLY_LAST_DAY ? [ARRAY_LAST] : null,
          byMonth:
            value === RruleRepeatEnum.WORKDAY_HALF_YEAR
              ? [6]
              : value === RruleRepeatEnum.YEARLY_WEEKDAY
                ? [dtStart.month() + 1]
                : null,
          bySetPos: this.calculateSetPosition(value, monthlyWeekday)
        });

        if (this.mapperFreq(value) === RruleFreqEnum.WORKDAY_HALF_YEAR) {
          asyncScheduler.schedule(() => this.setWorkDayHalfYear());
        }

        if (this.mapperFreq(value) === RruleFreqEnum.WORKDAY_MONTHS) {
          !this.formValue && this.workDaySelectedMonths.setValue([1]);
        }
      });

    this.formGroup
      .get('freq')
      .valueChanges.pipe(
        filter(() => this.formGroup.get('repeat').value === RruleRepeatEnum.CUSTOM),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        this._resetValidation();
        this._checkDateTime();
        this.checkValidationWorkDays(value);

        switch (value) {
          case RruleFreqEnum.WEEKLY:
            this.formGroup.get('byWeekday').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.MONTHLY:
            this.formGroup.get('byMonthDay').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.YEARLY:
            this.formGroup.get('byMonth').addValidators([Validators.required]);
            this.formGroup.get('byMonthDay').addValidators([Validators.required]);
            break;
          case RruleFreqEnum.DAILY:
          default:
            break;
        }
      });

    this.formGroup.valueChanges
      .pipe(
        map(() => this.formGroup.getRawValue()),
        pairwise(),
        map(([prev, value]) => !isEqual(prev, value) && value),
        filter((value) => !!value),
        takeUntil(this._unsubscribeService)
      )
      .subscribe((value) => {
        if (value.dtStart) {
          this.formGroup.get('repeat').enable({ emitEvent: false });
        } else {
          this.formGroup.get('repeat').disable({ emitEvent: false });
        }
      });
  }

  private _getRepeatValue(rrule: IRrule): RruleRepeatEnum {
    const monthlyWeekday = Math.floor((moment(rrule.dtStart).date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);

    if (rrule.freq === RruleFreqEnum.WORKDAY_HALF_YEAR) {
      return RruleRepeatEnum.WORKDAY_HALF_YEAR;
    }

    if (rrule.freq === RruleFreqEnum.WORKDAY_EACH_MONTH) {
      return RruleRepeatEnum.WORKDAY_EACH_MONTH;
    }

    if (rrule.freq === RruleFreqEnum.WORKDAY_MONTHS) {
      return RruleRepeatEnum.WORKDAY_MONTHS;
    }

    if (rrule.interval === MIN_COMMITTEE_INTERVAL) {
      if (rrule.freq === RruleFreqEnum.DAILY) {
        if (rrule.byWeekday?.length) {
          return RruleRepeatEnum.DAILY_WEEKDAY;
        } else {
          return RruleRepeatEnum.DAILY;
        }
      } else if (rrule.freq === RruleFreqEnum.WEEKLY) {
        if (
          rrule.byWeekday?.length === 5 &&
          [Days.MO, Days.TU, Days.WE, Days.TH, Days.FR]
            .map((day) => day.toString())
            .every((w) => rrule.byWeekday.includes(w))
        ) {
          return RruleRepeatEnum.DAILY_WEEKDAY;
        } else if (rrule.byWeekday?.length) {
          return RruleRepeatEnum.CUSTOM;
        } else {
          return RruleRepeatEnum.WEEKLY;
        }
      } else if (rrule.freq === RruleFreqEnum.MONTHLY) {
        if (rrule.bySetPos?.length === 1 && rrule.bySetPos[0] === monthlyWeekday) {
          return RruleRepeatEnum.MONTHLY_WEEKDAY;
        } else if (rrule.bySetPos?.length === 1 && rrule.bySetPos[0] === ARRAY_LAST) {
          return RruleRepeatEnum.MONTHLY_LAST_FRIDAY;
        } else if (rrule.byMonthDay?.length) {
          if (rrule.byMonthDay[0] === ARRAY_LAST) {
            return RruleRepeatEnum.MONTHLY_LAST_DAY;
          } else {
            return RruleRepeatEnum.CUSTOM;
          }
        } else {
          return RruleRepeatEnum.MONTHLY;
        }
      } else if (rrule.freq === RruleFreqEnum.YEARLY) {
        if (rrule.bySetPos?.length === 1 && rrule.bySetPos[0] === monthlyWeekday) {
          return RruleRepeatEnum.YEARLY_WEEKDAY;
        } else if (rrule.byMonthDay?.length) {
          return RruleRepeatEnum.CUSTOM;
        } else {
          return RruleRepeatEnum.YEARLY;
        }
      }
    } else {
      return RruleRepeatEnum.CUSTOM;
    }
  }

  private calculateFreq(value: RruleRepeatEnum): RruleFreqEnum | null {
    const mapping: Partial<Record<RruleRepeatEnum, RruleFreqEnum>> = {
      [RruleRepeatEnum.DAILY]: RruleFreqEnum.DAILY,
      [RruleRepeatEnum.DAILY_WEEKDAY]: RruleFreqEnum.DAILY,
      [RruleRepeatEnum.CUSTOM]: RruleFreqEnum.DAILY,
      [RruleRepeatEnum.WEEKLY]: RruleFreqEnum.WEEKLY,
      [RruleRepeatEnum.MONTHLY]: RruleFreqEnum.MONTHLY,
      [RruleRepeatEnum.MONTHLY_WEEKDAY]: RruleFreqEnum.MONTHLY,
      [RruleRepeatEnum.MONTHLY_LAST_FRIDAY]: RruleFreqEnum.MONTHLY,
      [RruleRepeatEnum.MONTHLY_LAST_DAY]: RruleFreqEnum.MONTHLY,
      [RruleRepeatEnum.YEARLY]: RruleFreqEnum.YEARLY,
      [RruleRepeatEnum.YEARLY_WEEKDAY]: RruleFreqEnum.YEARLY,
      [RruleRepeatEnum.WORKDAY_EACH_MONTH]: RruleFreqEnum.WORKDAY_EACH_MONTH,
      [RruleRepeatEnum.WORKDAY_HALF_YEAR]: RruleFreqEnum.WORKDAY_HALF_YEAR,
      [RruleRepeatEnum.WORKDAY_MONTHS]: RruleFreqEnum.WORKDAY_MONTHS
    };

    return mapping[value] || null;
  }

  private _resetValidation(): void {
    this.formGroup.get('interval').reset();
    this.formGroup.get('byWeekday').reset();
    this.formGroup.get('byMonth').reset();
    this.formGroup.get('bySetPos').reset();
    this.formGroup.get('byMonthDay').reset();
    this.formGroup.get('byWeekday').clearValidators();
    this.formGroup.get('byWorkDays').clearValidators();
    this.formGroup.get('byMonth').clearValidators();
    this.formGroup.get('bySetPos').clearValidators();
    this.formGroup.get('byMonthDay').clearValidators();
    this.formGroup.get('byWeekday').updateValueAndValidity({ emitEvent: false });
    this.formGroup.get('byMonth').updateValueAndValidity({ emitEvent: false });
    this.formGroup.get('byMonthDay').updateValueAndValidity({ emitEvent: false });
  }

  private _checkDateTime(): void {
    const dtStart = this.formGroup.get('dtStart').value;
    const timezone = this.formGroup.get('timezone').value;
    if (dtStart && moment.tz(dtStart, timezone).isBefore(moment(), 'date')) {
      this.currentDate = moment.tz(timezone).format(DATE_FORMAT);
      this.formGroup
        .get('dtStart')
        .setValidators([Validators.required, dateValidator({ min: this.currentDate })]);
      this.formGroup.get('dtStart').updateValueAndValidity({ emitEvent: false });
      this.formGroup.get('dtStart').markAsTouched();
    } else if (this.formGroup.get('dtstarttime').value) {
      this.formGroup
        .get('dtstarttime')
        .setValidators([
          Validators.required,
          minTimeValidator(timezone, this.formGroup.get('dtStart').value)
        ]);
      this.formGroup.get('dtstarttime').markAsTouched();
      this.formGroup.get('dtstarttime').updateValueAndValidity({ emitEvent: false });
    }
  }

  private _rruleFormMapper(): IRrule {
    return {
      ...this.formGroup.getRawValue(),
      ...this.daysOffForm
    };
  }

  private checkValidationWorkDays(freq: RruleFreqEnum | undefined): void {
    if (this.workDaysFreq.includes(freq)) {
      if (freq === RruleFreqEnum.WORKDAY_MONTHS) {
        this.workDaySelectedMonths.setValidators([Validators.required]);
      } else {
        this.workDaySelectedMonths.setValidators([]);
        this.workDaySelectedMonths.setValue([]);
      }

      if (freq === RruleFreqEnum.WORKDAY_HALF_YEAR) {
        this.setWorkDayHalfYear();
      }

      if (freq === RruleFreqEnum.WORKDAY_EACH_MONTH || freq === RruleFreqEnum.WORKDAY_MONTHS) {
        this.updateMaxWorkDays(freq);
      }
    } else {
      this.clearWorkDaysValidators();
    }
  }

  private updateMaxWorkDays(freq: RruleFreqEnum.WORKDAY_EACH_MONTH | RruleFreqEnum.WORKDAY_MONTHS): void {
    const max = this.workDaysService.getMinNumberByFreq(
      freq,
      this.daysOffForm.excludeSaturdays,
      this.workDaySelectedMonths.value
    );
    this.maxWorkDayValidationNumber = max;
    this.workDayNumber.setValidators([Validators.required, Validators.min(1), Validators.max(max)]);
    this.workDayNumber.markAsTouched();
  }

  private clearWorkDaysValidators(): void {
    this.workDayNumber.setValidators([Validators.min(1), Validators.max(23)]);
    this.workDaySelectedMonths.setValue([]);
    this.formGroup.controls.byWorkDays.setValidators([]);
    this.formGroup.controls.byWorkDays.setValue([]);
    this.workDaySelectedMonths.setValidators([]);
  }

  private setWorkDays(): void {
    const { freq, byWorkDays, byMonth } = this.formValue;

    if (this.workDaysFreq.includes(freq) && byWorkDays) {
      this.workDayNumber.setValue(byWorkDays[0] || null);
      this.workDaySelectedMonths.setValue(byMonth);
      this.checkValidationWorkDays(freq);
    }
  }

  private isWorkdaysValid(): boolean {
    const { freq } = this.formGroup.value;
    if (this.workDaysFreq.includes(freq)) {
      this.workDayNumber.markAsTouched();

      if (freq === RruleFreqEnum.WORKDAY_MONTHS) {
        this.workDaySelectedMonths.markAsTouched();
        return this.workDayNumber.valid && this.workDaySelectedMonths.valid;
      }

      return Boolean(this.formGroup.value.byWorkDays);
    }
    return true;
  }

  private mapperFreq(freq: RruleRepeatEnum): RruleFreqEnum | undefined {
    const map = {
      [RruleRepeatEnum.WORKDAY_HALF_YEAR]: RruleFreqEnum.WORKDAY_HALF_YEAR,
      [RruleRepeatEnum.WORKDAY_EACH_MONTH]: RruleFreqEnum.WORKDAY_EACH_MONTH,
      [RruleRepeatEnum.WORKDAY_MONTHS]: RruleFreqEnum.WORKDAY_MONTHS
    };

    return map[freq.toString()];
  }

  private setWorkDayHalfYear(): void {
    const { excludeSaturdays } = this.daysOffForm;
    const june = 6;
    const workDay = this.workDaysService.getLastWorkDayInMonth(june, excludeSaturdays);
    this.workDayNumber.setValue(workDay);
  }

  private workdayEachMonthChangeSub() {
    this.workDayNumber.valueChanges
      .pipe(
        filter((d) => Boolean(d)),
        tap((day) => {
          this.formGroup.controls.byWorkDays.setValue([day]);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private selectedWorkDayMonthChangeSub() {
    this.workDaySelectedMonths.valueChanges
      .pipe(
        filter((days: number[]) => Boolean(days?.length)),
        tap((days) => {
          this.formGroup.controls.byMonth.setValue(days);
          const freq = this.formGroup.controls.freq.value;
          if (freq) {
            freq === RruleFreqEnum.WORKDAY_MONTHS && this.updateMaxWorkDays(freq);
            this.workDayNumber.updateValueAndValidity();
          }
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private checkByWeekDay(date: string): void {
    const { repeat } = this.formGroup.value;
    if ([RruleRepeatEnum.MONTHLY_WEEKDAY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(repeat)) {
      const dtStart = moment(date);
      this.formGroup.controls.byWeekday.setValue([WEEK_CODES[dtStart.weekday()]], { emitEvent: false });
    }
  }

  private checkSetPosition(date: string): void {
    const dtStart = moment(date);
    const { repeat } = this.formGroup.value;
    const monthlyWeekday = Math.floor((dtStart.date() + DAYS_IN_WEEK - 1) / DAYS_IN_WEEK);
    const position = this.calculateSetPosition(repeat, monthlyWeekday);
    this.formGroup.controls.bySetPos.setValue(position, { emitEvent: false });
  }

  private calculateSetPosition(value: RruleRepeatEnum, monthlyWeekday: number): number[] {
    return [RruleRepeatEnum.MONTHLY_WEEKDAY, RruleRepeatEnum.YEARLY_WEEKDAY].includes(value)
      ? [monthlyWeekday]
      : value === RruleRepeatEnum.MONTHLY_LAST_FRIDAY
        ? [ARRAY_LAST]
        : null;
  }
}
