export enum RruleRepeatEnum {
  DAILY,
  DAILY_WEEKDAY,
  WEEKLY,
  MONTHLY,
  MONTHLY_WEEKDAY,
  MONTHLY_LAST_FRIDAY,
  MONTHLY_LAST_DAY,
  YEARLY,
  YEARLY_WEEKDAY,
  WORKDAY_EACH_MONTH,
  WORKDAY_MONTHS,
  WORKDAY_HALF_YEAR,
  CUSTOM
}

export enum RruleFreqEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
  WORKDAY_EACH_MONTH = 'WORKDAY_EACH_MONTH',
  WORKDAY_MONTHS = 'WORKDAY_MONTHS',
  WORKDAY_HALF_YEAR = 'WORKDAY_HALF_YEAR'
}

export enum WeekCodeEnum {
  MO = 'MO',
  TU = 'TU',
  WE = 'WE',
  TH = 'TH',
  FR = 'FR',
  SA = 'SA',
  SU = 'SU'
}
