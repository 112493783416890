import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Self,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IEmployeeOption, IOption, ProtocolFormValue, IProtocolFormGroup } from '@common/types';
import { BehaviorSubject, debounceTime, filter, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { EmployeeService, UnsubscribeService } from '@common/services';
import { MIN_INTERVAL } from '@common/constants';
import { employeeOptionMapper } from '@common/modules/committees/committees-form/utils/adaptors';
import { FormAbstractionComponent } from '@common/shared/components/form-abstraction/form-abstraction.component';

@Component({
  selector: 'com-protocol',
  templateUrl: './protocol.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UnsubscribeService]
})
export class ProtocolComponent extends FormAbstractionComponent implements OnInit, OnChanges {
  @Output() valueChange = new EventEmitter<ProtocolFormValue>();
  @Output() employeesChange = new EventEmitter<IOption[]>();

  @Input() formValue: ProtocolFormValue;

  public formGroup: FormGroup<IProtocolFormGroup>;

  public selectorSearch$ = new Subject<string>();
  public employeeOptions$ = new BehaviorSubject<IEmployeeOption[]>([]);

  constructor(
    private readonly _employeeService: EmployeeService,
    @Self() private readonly _unsubscribeService: UnsubscribeService
  ) {
    super();
    this._createForm();
    this._searchSelectorChangeSub();
    this._formSub();
  }

  ngOnInit(): void {
    this.emitFormMethods();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('formValue' in changes && this.formValue) {
      this.formGroup.patchValue(this.formValue);
    }
  }

  private _createForm(): void {
    this.formGroup = new FormGroup<IProtocolFormGroup>({
      protocolAccessEmployee: new FormControl(null, [Validators.required]),
      formationPeriod: new FormControl(null, [Validators.required, Validators.min(MIN_INTERVAL)])
    });
  }

  private _formSub(): void {
    this.formGroup.valueChanges
      .pipe(
        tap(() => {
          this.valueChange.emit(this.formGroup.value);
        }),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }

  private _searchSelectorChangeSub(): void {
    this.selectorSearch$
      .pipe(
        debounceTime(300),
        filter((query) => typeof query === 'string'),
        switchMap((query) =>
          this._employeeService
            .retrieveEmployeeSearchForSelect(query || '')
            .pipe(tap((employees) => this.employeeOptions$.next(employees.map(employeeOptionMapper))))
        ),
        takeUntil(this._unsubscribeService)
      )
      .subscribe();
  }
}
