import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeakersPipe } from './speakers.pipe';
import { JoinPipe } from './join.pipe';
import { KeyNamePipe } from './key-name.pipe';
import { FormControlPipe } from './form-control.pipe';
import { OptionNamePipe } from './option-name.pipe';
import { OptionFieldPipe } from './option-field.pipe';
import { ConvertDurationPipe } from './convert-duration.pipe';
import { DetermineStatusPipe } from './determine-status.pipe';
import { MemberFullNamesPipe } from './member-fullnames.pipe';
import { FullNameByRolePipe } from './fullname-by-role.pipe';
import { ResolutionReadonlyPipe } from './resolution-readonly.pipe';
import { EmployeeStatusPipe } from './employee-status.pipe';
import { CallbackPipe } from './callback.pipe';
import { MembersByRolePipe } from './members-by-role.pipe';
import { CompareTimePipe } from './upload-before.pipe';
import { FileTypePipe } from './file-type.pipe';
import { FilterByFieldPipe } from './filter-by-field.pipe';
import { RepeatOptionsPipe } from './repeat-options.pipe';
import { DeclinationPipe } from './declination.pipe';
import { AverageRatingPipe } from './average-rating.pipe';
import { CommitteeEventStatusPipe } from '@common/pipes/committee-event-status.pipe';
import { MemberCheckAccessPipe } from '@common/pipes/member-check-access.pipe';
import { IsTimelineEntityBusyPipe } from '@common/pipes/is-timeline-entity-busy.pipe';
import { GetFormGroupPipe } from '@common/pipes/get-form-group.pipe';
import { IsRoleInListPipe } from '@common/pipes/is-role-in-list.pipe';
import { JoinStringPipe } from '@common/pipes/join-string.pipe';
import { GetSpeakerMaterialPipe } from '@common/pipes/get-speaker-material.pipe';
import { GetSystemAccountMaterialPipe } from '@common/pipes/get-system-account-material.pipe';
import { GetUserMaterialWithoutSpeakerPipe } from '@common/pipes/get-user-material-without-speaker.pipe';
import { DisableVotePipe } from '@common/pipes/disable-vote.pipe';

const PIPES = [
  CallbackPipe,
  ConvertDurationPipe,
  DeclinationPipe,
  DetermineStatusPipe,
  EmployeeStatusPipe,
  FileTypePipe,
  FilterByFieldPipe,
  FormControlPipe,
  FullNameByRolePipe,
  JoinPipe,
  JoinStringPipe,
  KeyNamePipe,
  OptionFieldPipe,
  OptionNamePipe,
  RepeatOptionsPipe,
  SpeakersPipe,
  MemberFullNamesPipe,
  MembersByRolePipe,
  ResolutionReadonlyPipe,
  CompareTimePipe,
  CommitteeEventStatusPipe,
  MemberCheckAccessPipe,
  GetFormGroupPipe,
  IsRoleInListPipe,
  IsTimelineEntityBusyPipe,
  GetSpeakerMaterialPipe,
  GetSystemAccountMaterialPipe,
  GetUserMaterialWithoutSpeakerPipe,
  DisableVotePipe
];

@NgModule({
  imports: [CommonModule],
  exports: [...PIPES, AverageRatingPipe],
  declarations: [...PIPES, AverageRatingPipe]
})
export class SharedPipesModule {}
